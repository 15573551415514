

















































































// Utils
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
// Types
import CompanyTypes from '@/store/types/CompanyTypes'
// Services
import Company from '@/services/Company'

@Component({
})
export default class ModalEditDistribution extends Vue {
  editShares: any = { showModal: false, updatePartners: 0 }
  partners: any = []
  totalShares = ''
  totalPercentage = 0
  percentageZero = false

  @Watch('editShares.updatePartners')
  updatePartners () { this.setData() }

  mounted () {
    this.editShares = this.$attrs.value
    this.setData()
  }

  noSpacesStart (value: string) {
    return value.trimStart()
  }

  onlyNumbersActions (value: string) {
    value = value.replace(/,/g, '').replace(/[^0-9]/g, '')
    value = Number(value) > 100000 ? '100000' : Number(value) === 0 ? '0' : Number(value).toString()
    return value.replace(/\d(?=(?:\d{3})+$)/g, '$&,')
  }

  onlyNumbersPercentage (value: string) {
    value = value.replace(/[^0-9.]/g, '')
    return Number(value) > 100 ? '100' : Number(value) <= 0 ? '0' : Number(value).toString()
  }

  NumbersActionsCheck (value: string) {
    value = value.replace(/,/g, '').replace(/[^0-9]/g, '')
    return Number(value) < 10000
  }

  calculatedShares () {
    this.totalPercentage = 0
    this.percentageZero = false
    this.partners.map((partner: any) => {
      this.totalPercentage += Number(partner.percentage)
      if (Number(partner.percentage) === 0) this.percentageZero = true
    })
  }

  setData () {
    this.totalShares = this.onlyNumbersActions(this.company.share_series[0].total_shares_in_series.toString())
    this.partners = this.company.partners.map((partner: any) => {
      return {
        name: partner.name,
        first_names: partner.first_names,
        percentage: partner.shares[0].percentage === null ? '0' : partner.shares[0].percentage.toString(),
        id: partner.id
      }
    })
    this.calculatedShares()
  }

  async saveDistribution () {
    this.loading(true)
    const total = Number(this.totalShares.replace(/,/g, '').replace(/[^0-9]/g, ''))
    const [error] = await Company.storeShares(this.company.id, total, this.partners)
    if (error) {
      this.loading(false)
      return this.$toasted.global.error(error)
    }
    await this.updateCompany(this.company.id)
    this.editShares.updatePartners++
    this.loading(false)
    this.editShares.showModal = false
    this.$toasted.global.success({ message: 'Se edito la distribución de la empresa' })
  }

  @Getter(`CompanyModule/${CompanyTypes.getters.GET_DATA}`) company: any
  @Action(`CompanyModule/${CompanyTypes.actions.UPDATE_DATA}`) updateCompany: any
  @Action('UPDATE_STATE_LOADING') loading: any
}
