






























































































































































































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
// Types
import CompanyTypes from '@/store/types/CompanyTypes'

@Component({
})
export default class ModalStatusExpedient extends Vue {
  commissary: any = [{}]
  status: any = { showModal: false }
  collapsePartner: any = []

  mounted () {
    this.status = this.$attrs.value
    this.commissary = this.getNameForRoleInCouncil('commissary')
    for (let i = 0; i < this.company.partners.length; i++) this.collapsePartner.push({ show: false })
    this.$root.$on('bv::collapse::state', (collapseId: string, isJustShown: boolean) => {
      this.collapsePartner[Number(collapseId.replace(/[^0-9.]/g, ''))].show = isJustShown
    })
  }

  getNameForRoleInCouncil (role: string) {
    return this.company.council.filter((council: any) => council.council_role === role)
  }

  searchDocumentWithErrors (partner: any) {
    let complete = true
    const complementaries = partner.civil_status === 'soltero'
      ? partner.cfi.document_url === null
        ? false
        : partner.cfi.is_approved && partner.cfi.rejection_category === null
      : (partner.cfi.document_url === null ||
        partner.id_spouse.document_url === null ||
        partner.marriage_certificate.document_url === null
        ? false
        : partner.cfi.is_approved && partner.cfi.rejection_category === null &&
          partner.id_spouse.is_approved && partner.id_spouse.rejection_category === null &&
          partner.marriage_certificate.is_approved && partner.marriage_certificate.rejection_category === null)
    if (!complementaries || partner.rfc === '' || !partner.mati_verification) complete = false
    return complete
  }

  @Getter(`CompanyModule/${CompanyTypes.getters.GET_DATA}`) company: any
  @Action(`CompanyModule/${CompanyTypes.actions.UPDATE_DATA}`) updateCompany: any
  @Action('UPDATE_STATE_LOADING') loading: any
}
