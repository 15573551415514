



































// Utils
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
// Types
import CompanyTypes from '@/store/types/CompanyTypes'
// Services
import RenderDocs from '@/services/RenderDocs'

@Component({
})
export default class ModalRenderProtocolized extends Vue {
  file: any = null
  renderModal: any = { showModal: false }

  @Watch('renderModal.showModal')
  load () {
    if (this.renderModal.showModal) this.getRender()
  }

  mounted () {
    this.renderModal = this.$attrs.value
  }

  async getRender () {
    this.loading(true)
    const [error, response] = await RenderDocs.renderProtocolized(this.company.id)
    if (error) {
      this.loading(false)
      return this.$toasted.global.error(error)
    }
    const reader = new FileReader()
    reader.readAsDataURL(new Blob([response.data]))
    reader.onload = () => {
      if (reader.result) {
        if (typeof reader.result === 'string') this.file = reader.result.replace('application/octet-stream', 'application/pdf')
      }
    }
    this.loading(false)
  }

  @Getter(`CompanyModule/${CompanyTypes.getters.GET_DATA}`) company: any
  @Action(`CompanyModule/${CompanyTypes.actions.UPDATE_DATA}`) updateCompany: any
  @Action('UPDATE_STATE_LOADING') loading: any
}
