export default {
  mutations: {
    SET_DATA: 'SET_DATA'
  },
  getters: {
    GET_DATA: 'GET_DATA'
  },
  actions: {
    UPDATE_DATA: 'UPDATE_DATA'
  }
}
