
































































import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
import Company from '@/services/Company'
import moment from 'moment'
import numeral from 'numeral'

@Component({
  filters: {
    currency (value: number) { return numeral(value).format('0,0.00') }
  }
})
export default class Denominations extends Vue {
  companySelected = ''
  amount = ''
  showModal = false
  currentPage = 1
  perPage = 12
  moment = moment
  tableData: any = {
    fields: [
      { key: 'notary', label: 'Responsable', sortable: false },
      { key: 'responsable', label: 'Nombre del solicitante', sortable: false },
      { key: 'created_on', label: 'Fecha de incorporación', sortable: false },
      { key: 'payment', label: 'Pago', sortable: false },
      { key: 'company_id', label: '' }
    ],
    items: []
  }

  get totalRows () {
    return this.tableData.items.length
  }

  mounted () {
    this.getCompaniesWithoutLegalName()
  }

  async getCompaniesWithoutLegalName () {
    this.tableData.items = []
    this.loading(true)
    const [error, response] = await Company.getCompaniesWithoutLegalName()
    this.loading(false)
    if (error) return this.$toasted.global.error(error)
    this.formattedDataTable(response.data.data)
  }

  formattedDataTable (companies: any) {
    for (const company of companies) {
      this.tableData.items.push({
        legal_name: company.legal_name,
        notary: company.notary.name,
        created_on: company.company_created_on_timestamp,
        responsable: company.user.full_name,
        payment: (company.user.orders.length) ? this.getAmountFormatted(company.user.orders[0].order_total) : null,
        company_id: company.id
      })
    }
  }

  getAmountFormatted (amount: number) {
    return amount.toString().length > 5 ? (amount / 100) : amount
  }

  async createPayment () {
    this.loading(true)
    const [error] = await Company.createPaymentCompanyManual(this.companySelected, Number(this.amount))
    if (error) {
      this.loading(false)
      return this.$toasted.global.error(error)
    }
    this.showModal = false
    this.$toasted.global.success({ message: 'Se creo el pago con éxito' })
    this.getCompaniesWithoutLegalName()
  }

  @Action('UPDATE_STATE_LOADING') loading: any
  @State('UserModule') state: any
}
