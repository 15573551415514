


































import EventBus from '@/event-bus'
import MenuItem from '@/interfaces/MenuItem'
import MenuItems from '@/utils/menu-items'
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'

@Component
export default class LateralMenu extends Vue {
  menuItems: Array<MenuItem> = [];
  activeMenu = false;

  mounted () {
    this.menuItems = MenuItems
    EventBus.$on('show-menu', () => { this.activeMenu = true })
  }

  @State('UserModule') state: any;
}
