







// Utils
import { Component, Vue } from 'vue-property-decorator'
// Services
import NotariesRequests from '@/components/tables/NotariesRequests.vue'

@Component({
  components: { NotariesRequests }
})
export default class Coupons extends Vue {
}
