











import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ReturnPage extends Vue {
  @Prop({ required: true }) returnTo!: string;
}
