/* eslint-disable camelcase */
import to from 'await-to-js'
import axios from '@/utils/axios-api'

const URL_PHP = process.env.VUE_APP_BASE_URL_PHP

export default class Company {
  static async getCompanies () {
    return await to(axios.get(`${URL_PHP}/v1/admin/companies`)) as any
  }

  static async getCompaniesPaginated (page: number, search: string) {
    return await to(axios.get(`${URL_PHP}/v1/admin/companies?page=${page}${search}`)) as any
  }

  static async getCompaniesPending () {
    return await to(axios.get(`${URL_PHP}/v1/admin/companies/pending`)) as any
  }

  static async getCompaniesWithoutLegalName () {
    return await to(axios.get(`${URL_PHP}/v1/admin/companies/without-legalname`)) as any
  }

  static async getLegalNamesByCompanyId (id: string) {
    return await to(axios.get(`${URL_PHP}/v1/admin/company/${id}/legal-names`)) as any
  }

  static async getCompaniesComplete () {
    return await to(axios.get(`${URL_PHP}/v1/admin/companies/complete`)) as any
  }

  static async getProfileCompanyById (id: string) {
    return await to(axios.get(`${URL_PHP}/v1/admin/companies/${id}/profile`)) as any
  }

  static async updateStageByCompanyId (id: string, stageName: string) {
    return await to(axios.post(`${URL_PHP}/v1/company/update_stage/${id}`, { stage_name: stageName }))
  }

  static async approvedOrRejectLegalName (id: number, uniqDenomination: string, datetime: number, isApproved: boolean, unique_document_key: string, total_pages: string) {
    return await to(axios.put(`${URL_PHP}/v1/admin/legal-name/${id}/approved-rejected`, {
      clave_unica_denominacion: uniqDenomination,
      authorization_timestamp: datetime,
      is_approved: isApproved,
      unique_document_key: unique_document_key,
      total_pages: total_pages
    }))
  }

  static async rejectLegalNames (company: string) {
    return await to(axios.post(`${URL_PHP}/v1/company/${company}/reject-legal-names`))
  }

  static async createPaymentCompanyManual (id: string, amount: number) {
    return await to(axios.post(`${URL_PHP}/v1/admin/company/${id}/payment-manual`, { amount }))
  }

  static async updateSharesAndCouncil (data: any, company: string) {
    return await to(axios.post(`${URL_PHP}/v1/admin/company/update/council/${company}`, data)) as any
  }

  static async updatePartnerInfo (data: any, partner: string, company: string) {
    return await to(axios.post(`${URL_PHP}/v1/admin/update-partner/${partner}/company/${company}`, data)) as any
  }

  static async updateCompany (company: string, company_type: string, company_activity: {activity: string, products: string, description: string}) {
    return await to(axios.put(`${URL_PHP}/v2/update-company/${company}`, { company_type, company_activity })) as any
  }

  static async getStatesByZipCode (zip_code: string) {
    return await to(axios.get(`${URL_PHP}/v1/state/postal_code/${zip_code}`))
  }

  static async setCommissaryName (company: string, commissary: string) {
    return await to(axios.post(`${URL_PHP}/v2/set-name-commissary/${company}`, { commissary }))
  }

  static async getRenderConstitutive (company: string, is_approved: any) {
    return await to(axios.post(`${URL_PHP}/v2/company/render-constitutive/${company}`, { is_approved })) as any
  }

  static async endEdit (company: string) {
    return await to(axios.post(`${URL_PHP}/v2/set-timer/${company}`)) as any
  }

  static async setWeetrust (company: string, weetrust_id: string) {
    return await to(axios.post(`${URL_PHP}/v2/update-weetrust-id-company/${company}`, { weetrust_id })) as any
  }

  static async sendInvitations (company: string) {
    return await to(axios.post(`${URL_PHP}/v2/partners/send-invitations/${company}`)) as any
  }

  static async storeShares (company: string, total_shares: number, partners: Array<any>) {
    return await to(axios.post(`${URL_PHP}/v2/partners-shares/${company}`, { total_shares, partners })) as any
  }

  static async storeAdministration (council_type: string, partners: Array<any>, segment_event: boolean) {
    return await to(axios.post(`${URL_PHP}/v2/company/admin-faculties`, { council_type, partners, segment_event })) as any
  }
}
