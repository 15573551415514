









































// Utils
import { Component, Vue, Prop } from 'vue-property-decorator'
import EventBus from '@/event-bus'

@Component({
})
export default class ViewFile extends Vue {
  @Prop() fileName!: string
  @Prop() doc!: any

  openModalDocuments (document: any) {
    EventBus.$emit('open-modal', document.label ? document : { label: this.fileName, ...document })
  }

  previewOrDownload (document: any) {
    EventBus.$emit('open-preview-document-company', document)
  }
}
