import { required, integer } from 'vuelidate/lib/validators'

const validations = {
  coupon: {
    couponSelected: {
      name: { required },
      max_usage_times: { required, integer },
      discount: { required, integer },
      expire_at: { required }
    }
  }
}

export default validations
