import { required, requiredIf } from 'vuelidate/lib/validators'

const validations = {
  file: { required },
  modalApproved: {
    uniqDenomination: { required },
    authorizationDate: { required: requiredIf('isApproved') }
  }
}

export default validations
