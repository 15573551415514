






































































































































// Utils
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'
import numeral from 'numeral'
import moment from 'moment'
import { debounce } from 'lodash'
// Services
import Notary from '@/services/Notary'

@Component({
  filters: {
    currency (value: number) { return numeral(value).format('0,0.00') }
  }
})
export default class NotariesRequest extends Vue {
  search = ''
  currentSearch = ''
  page = 1
  currentPage = 1
  totalPages = 1
  moment = moment
  notaries: any = []
  loader = true

  @Watch('page')
  updateNotaries () {
    this.loader = true
    this.getNotaries()
  }

  searchNotary = debounce(async (search: string) => {
    await this.getNotarySearch(search)
  }, 500)

  async mounted () {
    this.prepareData()
  }

  async prepareData () {
    this.loading(true)
    try {
      await this.getNotaries()
      this.loading(false)
    } catch (error) {
      this.loading(false)
    }
  }

  async getNotarySearch (search: string) {
    this.search = search = search.trimStart()
    if (search === '' && this.currentSearch === '') {
      this.loader = false
      return
    }
    this.loader = true
    this.page = 1
    const [error, response] = await Notary.getNotariesPaginated(this.page, '&search=' + search)
    if (error) {
      this.loader = false
      this.page = this.currentPage
      return this.$toasted.global.error(error)
    }
    this.currentPage = 1
    this.totalPages = response.data.data.last_page
    this.currentSearch = search
    this.notaries = response.data.data.data
    this.loader = false
  }

  async getNotaries () {
    const [error, response] = await Notary.getNotariesPaginated(this.page, '&search=' + this.search)
    if (error) {
      this.loader = false
      this.loading(false)
      return this.$toasted.global.error(error)
    }
    this.currentPage = this.page
    this.totalPages = response.data.data.last_page
    this.notaries = response.data.data.data
    this.loader = false
    this.loading(false)
  }

  @Action('UPDATE_STATE_LOADING') loading: any
  @State('UserModule') state: any
}
