




















































import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import InputFile from '@/components/InputFile.vue'
import moment from 'moment'
import CompanyTypes from '@/store/types/CompanyTypes'
import PartnersDataPending from '@/components/user/PartnersDataPending.vue'
moment.locale('es')

@Component({
  components: { InputFile, PartnersDataPending }
})
export default class Detail extends Vue {
  eventDate = 'No se pudo cargar fecha del evento'
  // signatureMeeting = false
  dateSignature = null

  mounted () {
    // this.signatureMeeting = this.validateStageComplete(this.company, 'Notary_validation')
    // if (this.signatureMeeting) this.formattedDate()
    this.dateSignature = this.validateFlags(this.company)
  }

  formattedDate () {
    const findEvent = this.company.events.find((event: any) => event.event_description === 'constituir_empresa')
    if (!findEvent) return this.$toasted.global.error({ message: 'No se pudo cargar fecha del evento' })
    const date = new Date(findEvent.event_timestamp)
    const aux = moment(date)
    this.eventDate = ''
    this.eventDate += aux.format('dddd') + ', '
    this.eventDate += aux.format('DD') + ' de ' + aux.format('MMMM') + ' de ' + aux.format('YYYY')
    this.eventDate += ' - ' + aux.format('hh:mm a')
  }

  // validateStageComplete (company: any, stageName: string) { return company.stages.findIndex((stage: any) => stage.stage_name === stageName && stage.is_completed) !== -1 }
  validateFlags (company: any) {
    return company.flags.date_signature !== null ? this.company.flags.date_signature : null
  }

  @Action('UPDATE_STATE_LOADING') loading: any
  @Getter(`CompanyModule/${CompanyTypes.getters.GET_DATA}`) company: any
}
