





























// Utils
import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
// Types
import CompanyTypes from '@/store/types/CompanyTypes'

@Component({
  components: { }
})
export default class UserInfo extends Vue {
  fullName = ''
  email = ''
  phone = ''
  phoneCountryCode = ''

  mounted () {
    this.fullName = this.company.user.full_name !== null ? this.company.user.full_name : this.company.user.name
    this.email = this.company.user.email
    this.phone = this.company.user.phone
    this.phoneCountryCode = this.company.user.phone_country_code
  }

  @Getter(`CompanyModule/${CompanyTypes.getters.GET_DATA}`) company: any
}
