







// Utils
import { Component, Vue } from 'vue-property-decorator'
// Components
import CompaniesRequests from '@/components/tables/CompaniesRequests.vue'

@Component({
  components: { CompaniesRequests }
})
export default class RequestDetail extends Vue {
}
