



























































import { Component, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import UserService from '@/services/User'
import { validationMixin } from 'vuelidate'
import validations from '@/validations/recovery-password'

@Component({
  mixins: [validationMixin],
  validations: validations
})
export default class Recovery extends Vue {
  email = ''

  async recovery () {
    this.UPDATE_STATE_LOADING(true)
    const [error] = await UserService.resetPasswordEmail({ identity: this.email })
    this.UPDATE_STATE_LOADING(false)
    if (error) return this.$toasted.global.error(error)
    return this.$router.push({ name: 'EmailRecoverySent', query: { m: this.email } })
  }

  @Action('UPDATE_STATE_LOADING') UPDATE_STATE_LOADING: any
}
