



















































import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
import Company from '@/services/Company'
import moment from 'moment'

@Component
export default class Resumen extends Vue {
  currentPage = 1
  perPage = 12
  moment = moment
  tableData: any = {
    fields: [
      { key: 'notary', label: 'Responsable', sortable: false },
      { key: 'legal_name', label: 'Empresa', sortable: false },
      { key: 'created_on', label: 'Fecha de incorporación', sortable: false },
      { key: 'responsable', label: 'Nombre del solicitante', sortable: false },
      { key: 'articles_of_incorporation', label: 'Testimonio', sortable: false },
      { key: 'fiscal_identity', label: 'RFC', sortable: false },
      { key: 'company_id', label: '' }
    ],
    items: []
  }

  get totalRows () {
    return this.tableData.items.length
  }

  mounted () {
    this.getCompaniesComplete()
  }

  async getCompaniesComplete () {
    this.loading(true)
    const [error, response] = await Company.getCompaniesComplete()
    this.loading(false)
    if (error) return this.$toasted.global.error(error)
    this.formattedDataTable(response.data.data)
  }

  formattedDataTable (companies: any) {
    for (const company of companies) {
      this.tableData.items.push({
        legal_name: company.legal_name,
        notary: company.notary.name,
        created_on: company.company_created_on_timestamp,
        responsable: (company.user) ? company.user.full_name : 'pendiente de checar',
        company_id: company.id,
        articles_of_incorporation: this.getDocumentByType(company, 'articles_of_incorporation'),
        fiscal_identity: this.getDocumentByType(company, 'fiscal_identity')
      })
    }
  }

  getDocumentByType (company: any, type: string) {
    const findDocument = company.documents.find((document: any) => document.document_type === type)
    if (findDocument) {
      return {
        document_url: findDocument.document_url,
        is_approved: findDocument.is_approved,
        rejection_category: findDocument.rejection_category,
        id: findDocument.id,
        type,
        company_id: company.id
      }
    }
    return { document_url: null }
  }

  @Action('UPDATE_STATE_LOADING') loading: any
  @State('UserModule') state: any
}
