





















































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import moment from 'moment'
// Types
import CompanyTypes from '@/store/types/CompanyTypes'

moment.locale('es')
@Component({
})
export default class ShippingInfo extends Vue {
  @Getter(`CompanyModule/${CompanyTypes.getters.GET_DATA}`) company: any
}
