





























































import { Component, Vue } from 'vue-property-decorator'
import ConversMixin from '@/mixins/Converts'

@Component({
  mixins: [ConversMixin]
})
export default class InputFile extends Vue {
  file: any = null;

  updateFile (file: any) {
    this.$emit('input', file)
  }
}
