


































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { validationMixin } from 'vuelidate'
import validations from '@/validations/notary'
import Notary from '@/services/Notary'
import States from '@/services/States'

@Component({
  mixins: [validationMixin],
  validations: validations
})
export default class Coupons extends Vue {
  name = ''
  phone = ''
  email = ''
  address = ''
  postalCode = ''
  state = ''
  states = []
  country = 'México'
  contactNames = ''
  contactLastNames = ''
  googleMapsUrl = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d240863.68495829732!2d-99.28369685137199!3d19.391003836427828!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85ce0026db097507%3A0x54061076265ee841!2sCiudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1ses-419!2smx!4v1600625937412!5m2!1ses-419!2smx'

  mounted () {
    this.getNotaries()
  }

  async getNotaries () {
    const [error, response] = await States.index()
    if (error) return this.$toasted.global.error(error)
    this.states = response.data.data
  }

  async store () {
    const data = {
      name: this.name,
      address: this.address,
      zip_code: this.postalCode,
      state: this.state,
      country: this.country,
      phone: this.phone,
      goolge_maps: this.googleMapsUrl,
      contact_email: this.email,
      contact_name: this.contactNames,
      last_name: this.contactLastNames
    }
    this.loading(true)
    const [err] = await Notary.store(data)
    this.loading(false)
    if (err) return this.$toasted.global.error(err)
    this.$router.push({ name: 'Notaries' })
  }

  @Action('UPDATE_STATE_LOADING') loading: any
}
