

























// Utils
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
// Components
import ModalStatusExpedient from '@/components/user/companyDetail/ModalStatusExpedient.vue'
// Types
import CompanyTypes from '@/store/types/CompanyTypes'
// Services
import Company from '@/services/Company'

@Component({
  components: { ModalStatusExpedient }
})
export default class StatuteDataPending extends Vue {
  status = { showModal: false }

  getNameForRoleInCouncil (role: string) {
    return this.company.council.filter((council: any) => council.council_role === role)
  }

  searchDocumentWithErrors () {
    let complete = true
    const commissary = this.getNameForRoleInCouncil('commissary')
    this.company.partners.map((partner: any) => {
      const complementaries = partner.civil_status === 'soltero'
        ? partner.cfi.document_url === null
          ? false
          : partner.cfi.is_approved && partner.cfi.rejection_category === null
        : (partner.cfi.document_url === null ||
          partner.id_spouse.document_url === null ||
          partner.marriage_certificate.document_url === null
          ? false
          : partner.cfi.is_approved && partner.cfi.rejection_category === null &&
            partner.id_spouse.is_approved && partner.id_spouse.rejection_category === null &&
            partner.marriage_certificate.is_approved && partner.marriage_certificate.rejection_category === null)
      if (!complementaries || partner.rfc === '' || !partner.mati_verification) complete = false
    })
    if (commissary.length && commissary[0].other_faculty !== 'Partner') {
      if (!this.company.commissary_council.documents.length) complete = false
    }
    return complete
  }

  async approvedDocument () {
    this.loading(true)
    const [error] = await Company.updateStageByCompanyId(this.company.id, 'documents_approved')
    if (error) {
      this.loading(false)
      return this.$toasted.global.error(error)
    }
    const [error2] = await Company.updateStageByCompanyId(this.company.id, 'partners_data')
    if (error2) {
      this.loading(false)
      return this.$toasted.global.error(error2)
    }
    const [err] = await this.setCompanyData(this.company.id)
    if (err) return this.$toasted.global.error(err)
    await this.saveRender()
    this.$toasted.global.success({ message: 'Se aprobo el expediente con éxito' })
  }

  async saveRender () {
    this.loading(true)
    /* const [error] = await Company.getRenderConstitutive(this.company.id, true)
    if (error) {
      this.loading(false)
      return this.$toasted.global.error(error)
    } */
    const [error2] = await Company.endEdit(this.company.id)
    if (error2) {
      this.loading(false)
      return this.$toasted.global.error(error2)
    }
    const [err] = await this.setCompanyData(this.company.id)
    this.loading(false)
    if (err) return this.$toasted.global.error(err)
    this.$toasted.global.success({ message: 'Se genero el acta renderizada con éxito' })
  }

  @Getter(`CompanyModule/${CompanyTypes.getters.GET_DATA}`) company: any
  @Action('UPDATE_STATE_LOADING') loading: any
  @Action(`CompanyModule/${CompanyTypes.actions.UPDATE_DATA}`) setCompanyData: any
}
