import Axios from 'axios'
import router from '@/router/index'
import store from '@/store/index'
import AuthTypes from '@/store/types/AuthTypes'
import to from 'await-to-js'

const axios = Axios.create({})

axios.interceptors.request.use(async (config) => {
  const [err, payload] = await to(store.getters[`AuthModule/${AuthTypes.getters.GET_PAYLOAD}`]) as any
  if (err) return config
  if (payload.access_token !== '') config.headers.authorization = `Bearer ${payload.access_token}`
  return config
})

axios.interceptors.response.use((config) => config,
  async (error) => {
    const originalRequest = error.config
    if (originalRequest.url.includes('user/refresh')) {
      await store.dispatch(`AuthModule/${AuthTypes.actions.LOGOUT}`)
      await store.dispatch('UPDATE_STATE_LOADING', false)
      router.push({ name: 'Login' })
      return window.location.reload()
    } else if (error.response.data.error && error.response.data.error[0].includes('token')) {
      await store.dispatch('UPDATE_STATE_LOADING', true)
      const [err, response] = await store.dispatch(`AuthModule/${AuthTypes.actions.REFRESH_TOKEN}`)
      await store.dispatch('UPDATE_STATE_LOADING', false)
      if (err) return router.push({ name: 'Login' })
      const retryOriginalRequest = new Promise((resolve) => {
        originalRequest.headers.Authorization = 'Bearer ' + response.access_token
        return resolve(axios(originalRequest))
      })
      return retryOriginalRequest
    }
    return Promise.reject(error)
  })

export default axios
