import to from 'await-to-js'
import axios from '@/utils/axios-api'

const URL_PHP = process.env.VUE_APP_BASE_URL_PHP

export default class States {
  static async index () {
    return await to(axios.get(`${URL_PHP}/v1/admin/states`)) as any
  }
}
