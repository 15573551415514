






















































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import CompanyTypes from '@/store/types/CompanyTypes'

@Component
export default class RepresentativeStatute extends Vue {
  @Prop() representantes: any
  validEmail = true
  collapseRepresentante = { index: -1, show: false }
  representante: any = {
    id: '',
    name: '',
    rfc: '',
    is_primary: false
  }

  created () {
    this.representante = this.$attrs.value
    for (let i = 0; i < this.company.legal_representatives.length; i++) {
      if (this.company.legal_representatives[i].id === this.representante.id) this.collapseRepresentante.index = i
    }
    if (this.collapseRepresentante.index === -1) {
      let index = 1
      Object.values(this.representantes).forEach((partner: any) => {
        if (this.representante.id === partner.id) this.collapseRepresentante.index = index
        else index++
      })
    }
    this.$root.$on('bv::collapse::state', (collapseId: string, isJustShown: boolean) => {
      if (Number(collapseId.replace(/[^0-9.]/g, '')) === this.collapseRepresentante.index) this.collapseRepresentante.show = isJustShown
    })
  }

  noSpacesStart (value: string) {
    return value.trimStart()
  }

  validate (email: string) {
    const re = /\S+@\S+\.\S+/
    this.validEmail = re.test(email)
  }

  searchErrors () {
    return (this.representante.name === '' || !this.representante.name ||
      this.representante.rfc === '' || !this.representante.rfc)
  }

  @Getter(`CompanyModule/${CompanyTypes.getters.GET_DATA}`) company: any
}
