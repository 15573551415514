





























import { Component, Vue } from 'vue-property-decorator'
import EventBus from '@/event-bus'
import { Action } from 'vuex-class'
import AuthTypes from '@/store/types/AuthTypes'

@Component
export default class Header extends Vue {
  activeMenu () {
    EventBus.$emit('show-menu')
  }

  async signOff () {
    await this.logout()
    return this.$router.push({ name: 'Login' })
  }

  @Action(`AuthModule/${AuthTypes.actions.LOGOUT}`) logout: any;
}
