






















































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
import Company from '@/services/Company'
import DatePicker from 'vue2-datepicker'
import InputFile from '@/components/InputFile.vue'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/es'
import ReturnPage from '@/components/ReturnPage.vue'
import { validationMixin } from 'vuelidate'
import validations from '@/validations/approved-or-reject-denomination'
import Documents from '@/services/Documents'
import moment from 'moment'
import UploadImage from '@/interfaces/UploadImage'

@Component({
  components: { InputFile, ReturnPage, DatePicker },
  mixins: [validationMixin],
  validations: validations
})
export default class DenominationsDetail extends Vue {
  type = ''
  legalNameSelected = 0
  uniqDenomination = ''
  uniqDenominationKey = ''
  totalPages = ''
  file: any = ''
  authorizationDate: any = new Date()
  showModal = false
  currentPage = 1
  perPage = 12
  moment = moment
  tableData: any = {
    fields: [
      { key: 'name', label: 'Autorización de denominación', sortable: false },
      { key: 'created_on', label: 'Fecha de solicitud', sortable: false },
      { key: 'actions', label: 'Acciones', sortable: false }
    ],
    items: []
  }

  @Watch('authorizationDate', { immediate: true, deep: true })
  changeBirthdate (currentValue: any) {
    this.authorizationDate = moment(currentValue, 'DD-MM-YYYY').format('DD-MM-YYYY')
  }

  get isApproved () {
    return this.type === 'approved'
  }

  get totalRows () {
    return this.tableData.items.length
  }

  mounted () {
    this.getLegalNames()
  }

  onlyNumbers (value: string) {
    value = value.replace(/[^0-9]/g, '')
    return value.trimStart()
  }

  async getLegalNames () {
    this.tableData.items = []
    this.loading(true)
    const [error, response] = await Company.getLegalNamesByCompanyId(this.$route.params.company_id)
    this.loading(false)
    if (error) return this.$toasted.global.error(error)
    this.formattedDataTable(response.data.data)
  }

  formattedDataTable (company: any) {
    for (const legalName of company.legal_names) {
      this.tableData.items.push({
        name: legalName.name,
        created_on: legalName.created_on,
        legal_name_id: legalName.id,
        is_approved: legalName.is_approved,
        rejection_category: legalName.rejection_category
      })
    }
  }

  getAmountFormatted (amount: number) {
    return amount.toString().length > 5 ? (amount / 100) : amount
  }

  noSpacesStart (value: string) {
    return value.trimStart()
  }

  openModal (action: string, legalNameId: number) {
    this.type = action
    this.uniqDenomination = ''
    this.authorizationDate = new Date()
    this.legalNameSelected = legalNameId
    this.showModal = true
  }

  async approvedOrRejectLegalName () {
    this.loading(true)
    const datetime = moment(this.authorizationDate, 'DD-MM-YYYY').unix()
    const [error] = await Company.approvedOrRejectLegalName(this.legalNameSelected, this.uniqDenomination, datetime, this.type === 'approved', this.uniqDenominationKey, this.totalPages)
    if (error) {
      this.loading(false)
      return this.$toasted.global.error(error)
    }
    const data: UploadImage = {
      identity: this.$route.params.company_id,
      entity_type: 'company',
      document_type: 'legal_name_authorization',
      image: this.file,
      is_approved: this.type === 'approved' ? 'yes' : 'no'
    }
    const [errorUpload] = await Documents.uploadDocument(data)
    if (errorUpload) {
      this.loading(false)
      return this.$toasted.global.error(errorUpload)
    }
    this.showModal = false
    this.$toasted.global.success({ message: 'Se actualizo la información con éxito' })
    this.getLegalNames()
  }

  @Action('UPDATE_STATE_LOADING') loading: any
  @State('UserModule') state: any
}
