import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { State } from '@/store/interfaces/User'
import UserTypes from '@/store/types/UserTypes'
import UserService from '@/services/User'

const state: State = {
  id: 0,
  role: '',
  name: '',
  first_names: '',
  last_names: '',
  full_name: '',
  email: '',
  is_confirmed: false,
  is_active: false,
  notary_id: null,
  notary: null
}

const getters: GetterTree<State, any> = {
  [UserTypes.getters.GET_DATA]: stateGet => stateGet
}

const mutations: MutationTree<State> = {
  [UserTypes.mutations.SET_DATA]: async (stateMut, payload: State) => {
    state.id = payload.id
    state.role = payload.role
    state.name = payload.name
    state.first_names = payload.first_names
    state.last_names = payload.last_names
    state.full_name = payload.full_name
    state.email = payload.email
    state.is_confirmed = payload.is_confirmed
    state.is_active = payload.is_active
    state.notary_id = payload.notary_id
    state.notary = payload.notary
  }
}

const actions: ActionTree<State, any> = {
  [UserTypes.actions.UPDATE_DATA]: async ({ commit }) => {
    const [err, response]: any = await UserService.profile()
    if (err) return [err]
    await commit(UserTypes.mutations.SET_DATA, response.data.data)
    return [null, response.data.data]
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
