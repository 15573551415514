



































































// Utils
import { Component, Vue, Watch } from 'vue-property-decorator'
import { validationMixin } from 'vuelidate'
import { Action } from 'vuex-class'
import moment from 'moment'
import numeral from 'numeral'
// Validations
import validations from '@/validations/coupon'
// Components
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/es'
// Services
import Coupon from '@/services/Coupon'

@Component({
  mixins: [validationMixin],
  validations: validations,
  components: { DatePicker },
  filters: {
    currency (value: number) { return numeral(value).format('0,0.00') }
  }
})
export default class ModalCoupon extends Vue {
  moment = moment
  coupon: any = {
    showModal: false,
    update: false,
    type: '',
    couponSelected: { id: 0, name: '', state: '', phone: '', contact_email: '', discount: '', max_usage_times: '', expire_at: moment().format('DD-MM-YYYY') }
  }

  @Watch('coupon.couponSelected.expire_at', { immediate: true, deep: true })
  changeExpire (currentValue: any) { this.coupon.couponSelected.expire_at = moment(currentValue, 'DD-MM-YYYY').format('DD-MM-YYYY') }

  mounted () {
    this.coupon = this.$attrs.value
  }

  async store () {
    this.loading(true)
    const [error] = await Coupon.store({
      active: true,
      discount: Number(this.coupon.couponSelected.discount),
      discount_type: 'amount',
      eligibility: 'all',
      expire_at: moment(this.coupon.couponSelected.expire_at, 'DD-MM-YYYY').unix(),
      max_usage_times: Number(this.coupon.couponSelected.max_usage_times),
      name: this.coupon.couponSelected.name
    })
    if (error) {
      this.loading(false)
      return this.$toasted.global.error(error)
    }
    this.$toasted.global.success({ message: 'Se creo el cupón con éxito' })
    this.loading(false)
    this.coupon.showModal = false
    this.coupon.update = true
  }

  async edit () {
    this.loading(true)
    const [error] = await Coupon.update(this.coupon.couponSelected.nameEditBackup, {
      active: true,
      discount: Number(this.coupon.couponSelected.discount),
      discount_type: 'amount',
      eligibility: 'all',
      expire_at: moment(this.coupon.couponSelected.expire_at, 'DD-MM-YYYY').unix(),
      max_usage_times: Number(this.coupon.couponSelected.max_usage_times),
      name: this.coupon.couponSelected.name
    })
    if (error) {
      this.loading(false)
      return this.$toasted.global.error(error)
    }
    this.$toasted.global.success({ message: 'Se actualizo el cupón con éxito' })
    this.loading(false)
    this.coupon.showModal = false
  }

  @Action('UPDATE_STATE_LOADING') loading: any
}
