import Vue from 'vue'
import moment from 'moment'
moment.locale('es')

export default Vue.mixin({
  methods: {
    byteToKb (byte: number) {
      return (byte * 0.001).toFixed(0)
    },
    dateFormatUnix (dateUnix: number, format = 'LT') {
      return moment.unix(dateUnix).format(format)
    }
  }
})
