



































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import EventBus from '@/event-bus'
import moment from 'moment'
// Components
import ReturnPage from '@/components/ReturnPage.vue'
import PartnersDataPending from '@/components/user/PartnersDataPending.vue'
import CompanyInformation from '@/components/user/CompanyInformation.vue'
import CompanyStatute from '@/components/user/statuteRender/CompanyStatute.vue'
import Detail from '@/components/user/resumen/Detail.vue'
import DocumentsResumen from '@/components/user/resumen/Documents.vue'
// Types
import CompanyTypes from '@/store/types/CompanyTypes'
// Services
import Documents from '@/services/Documents'

moment.locale('es')
@Component({
  components: { ReturnPage, PartnersDataPending, CompanyInformation, CompanyStatute, Detail, DocumentsResumen }
})
export default class Requests extends Vue {
  showContent = 'partner'
  readyData = false
  showModal = false
  documentSelected: any = null
  previewPDF = false
  file: any = null

  async mounted () {
    EventBus.$on('open-preview-document-company', (document: any) => { this.previewOrDownload(document) })
    await this.prepareData()
    this.readyData = true
  }

  async prepareData () {
    this.loading(true)
    const [error] = await this.setCompanyData(this.$route.params.company_id)
    this.loading(false)
    if (error) return this.$toasted.global.error(error)
  }

  async previewOrDownload (document: any) {
    this.loading(true)
    this.showModal = false
    const [error, response] = await Documents.getDocument({
      entity_type: document.owner_category,
      identity: document.company_id,
      document_type: document.type || document.document_type
    })
    this.loading(false)
    if (error) return this.$toasted.global.error(error)
    this.documentSelected = document
    const reader = new FileReader()
    reader.readAsDataURL(new Blob([response.data]))
    reader.onload = () => {
      if (reader.result) {
        this.previewPDF = response.headers['content-type'].includes('application/pdf')
        if (typeof reader.result === 'string' && this.previewPDF) this.file = reader.result.replace('application/octet-stream', 'application/pdf')
        else this.file = reader.result
        this.showModal = true
      }
    }
  }

  @Getter(`CompanyModule/${CompanyTypes.getters.GET_DATA}`) company: any
  @Action('UPDATE_STATE_LOADING') loading: any
  @Action(`CompanyModule/${CompanyTypes.actions.UPDATE_DATA}`) setCompanyData: any
}
