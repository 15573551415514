import { requiredIf } from 'vuelidate/lib/validators'

const validations = {
  rejectedCategory: {
    required: requiredIf('isReject')
  },
  confirmApproved: {
    required: requiredIf('isApprove'),
    checked: (value: boolean, vm: any) => {
      if (vm.isApprove) return value
      return true
    }
  }
}

export default validations
