























































































































// Utils
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
// Types
import CompanyTypes from '@/store/types/CompanyTypes'
// Services
import Company from '@/services/Company'

@Component({
})
export default class ModalEditCouncil extends Vue {
  editCouncil: any = { showModal: false, updateCouncil: 0 }
  councilPresident = false
  councilSecretary = false
  partners: any = [{ id: '', name: '' }]
  partnerCommissary: any = { id: '', name: '' }
  uniqueAdministrator = { name: '', id: '' }
  president: any = { id: '', name: '' }
  secretary: any = { id: '', name: '' }
  members: any = []

  @Watch('president')
  updatePresident () { if (this.president.id !== '') this.filterPresident() }

  @Watch('secretary')
  updateSecretary () { if (this.secretary.id !== '') this.filterSecretary() }

  mounted () {
    this.editCouncil = this.$attrs.value
    const commissary = this.company.council.filter((partner: any) => partner.council_role === 'commissary')
    this.partners = this.company.partners.map((partner: any) => {
      let fullName = partner.name != null ? partner.name : ''
      fullName = partner.first_names != null ? fullName + ' ' + partner.first_names : fullName
      if (commissary.length > 0 && commissary[0].other_faculty === 'Partner' && commissary[0].partner_id === partner.id) this.partnerCommissary = { id: partner.id, name: fullName }
      return { id: partner.id, name: fullName }
    })
    this.partners = this.partners.filter((partner: any) => partner.id !== this.partnerCommissary.id)
    this.validateCouncil()
  }

  validateCouncil () {
    this.company.council.map((member: any) => {
      if (member.council_role === 'unique_administrator' && member.partner) {
        let fullName = member.partner.name != null ? member.partner.name : ''
        fullName = member.partner.first_names != null ? fullName + ' ' + member.partner.first_names : fullName
        this.uniqueAdministrator = {
          id: member.partner.id,
          name: fullName
        }
      }
      if (member.council_role === 'president' && member.partner) {
        let fullName = member.partner.name != null ? member.partner.name : ''
        fullName = member.partner.first_names != null ? fullName + ' ' + member.partner.first_names : fullName
        this.councilPresident = true
        this.president = {
          id: member.partner.id,
          name: fullName
        }
        this.filterSecretary()
      } else if (member.council_role === 'secretary' && member.partner) {
        let fullName = member.partner.name != null ? member.partner.name : ''
        fullName = member.partner.first_names != null ? fullName + ' ' + member.partner.first_names : fullName
        this.councilSecretary = true
        this.secretary = {
          id: member.partner.id,
          name: fullName
        }
        this.filterPresident()
      } else if (member.council_role === 'member' && member.partner) {
        let fullName = member.partner.name != null ? member.partner.name : ''
        fullName = member.partner.first_names != null ? fullName + ' ' + member.partner.first_names : fullName
        this.members.push({ id: member.partner.id, name: fullName })
      }
    })
    if (!this.councilPresident || !this.councilSecretary) {
      this.president.id = this.president.name = this.secretary.id = this.secretary.name = ''
    }
  }

  filterPresident () {
    if (this.president.id === this.secretary.id) this.secretary = { id: '', name: '' }
    this.filterMembers(this.president.id)
  }

  filterSecretary () {
    if (this.secretary.id === this.president.id) this.president = { id: '', name: '' }
    this.filterMembers(this.secretary.id)
  }

  filterMembers (id: string) {
    this.members.map((member: any, index: number) => {
      if (id === member.id) {
        this.members.splice(index, 1)
      }
    })
  }

  deleteMember (index: number) {
    this.members.splice(index, 1)
  }

  repeatMembers (id: string) {
    return this.members.length ? this.members.filter((member: any) => member.id === id).length : this.members.length
  }

  async saveCouncil () {
    this.loading(true)
    let council : any = []
    if (this.company.has_unique_administrator) {
      council = this.partners.map((partner: any) => {
        if (partner.id === this.uniqueAdministrator.id) return { id: partner.id, role: 'unique_administrator' }
        return { id: partner.id, role: 'no-member' }
      })
    } else {
      council = this.partners.map((partner: any) => {
        if (partner.id === this.president.id) return { id: partner.id, role: 'president' }
        if (partner.id === this.secretary.id) return { id: partner.id, role: 'secretary' }
        const members = this.members.filter((member: any) => member.id === partner.id)
        if (members.length) return { id: partner.id, role: 'member' }
        return { id: partner.id, role: 'no-member' }
      })
    }
    const [error] = await Company.storeAdministration(this.company.has_unique_administrator ? 'unique' : 'council', council, false)
    if (error) {
      this.loading(false)
      return this.$toasted.global.error(error)
    }
    await this.updateCompany(this.company.id)
    this.editCouncil.updateCouncil++
    this.loading(false)
    this.editCouncil.showModal = false
    this.$toasted.global.success({ message: 'Se edito el concejo de la empresa' })
  }

  @Getter(`CompanyModule/${CompanyTypes.getters.GET_DATA}`) company: any
  @Action(`CompanyModule/${CompanyTypes.actions.UPDATE_DATA}`) updateCompany: any
  @Action('UPDATE_STATE_LOADING') loading: any
}
