import DocumentService from '@/services/Documents'
import { saveAs } from 'file-saver'

export default class Documents {
  static async download (document: any) {
    const [error, response] = await DocumentService.getDocument({
      entity_type: document.owner_category,
      identity: document.company_id,
      document_type: document.type
    })
    if (error) return [error]
    const fileType = response.headers['content-type'].split('/')[1]
    const blob = new Blob([response.data])
    saveAs(blob, `${document.type}.${fileType}`)
    return [null]
  }
}
