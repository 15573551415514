import to from 'await-to-js'
import axios from '@/utils/axios-api'
import { Store } from '@/interfaces/Coupon'

const URL_PHP = process.env.VUE_APP_BASE_URL_PHP

export default class Coupon {
  static async index () {
    return await to(axios.get(`${URL_PHP}/v1/admin/coupons`)) as any
  }

  static async getCouponsPaginated (page: number, search: string) {
    return await to(axios.get(`${URL_PHP}/v1/admin/coupons?page=${page}${search}`)) as any
  }

  static async store (data: Store) {
    return await to(axios.post(`${URL_PHP}/v1/admin/coupon/create`, data)) as any
  }

  static async update (couponName: string, data: Store) {
    return await to(axios.put(`${URL_PHP}/v1/admin/coupon/${couponName}`, data)) as any
  }

  static async delete (couponName: string) {
    return await to(axios.delete(`${URL_PHP}/v1/admin/coupon/${couponName}`)) as any
  }
}
