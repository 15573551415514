






















import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import ReturnPage from '@/components/ReturnPage.vue'
import CompanyTypes from '@/store/types/CompanyTypes'
import Detail from '@/components/user/resumen/Detail.vue'
import CompanyInformation from '@/components/user/CompanyInformation.vue'

@Component({
  components: { ReturnPage, Detail, CompanyInformation }
})
export default class DetailResumen extends Vue {
  showContent = ''
  readyData = false

  async mounted () {
    await this.getProfileCompanyById()
    this.readyData = true
  }

  async getProfileCompanyById () {
    this.loading(true)
    const [error] = await this.setCompanyData(this.$route.params.company_id)
    this.loading(false)
    if (error) return this.$toasted.global.error(error)
    this.showContent = 'detail'
  }

  @Action('UPDATE_STATE_LOADING') loading: any
  @Getter(`CompanyModule/${CompanyTypes.getters.GET_DATA}`) company: any
  @Action(`CompanyModule/${CompanyTypes.actions.UPDATE_DATA}`) setCompanyData: any
}
