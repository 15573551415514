import { RouteConfig } from 'vue-router'
import multiguard from 'vue-router-multiguard'
import Index from '@/views/user/Index.vue'
import Home from '@/views/user/Home.vue'
import Resumen from '@/views/user/Resumen.vue'
import User from '@/middlewares/User'
import Requests from '@/views/user/Requests.vue'
import Denominations from '@/views/user/Denominations.vue'
import DenominationsDetail from '@/views/user/DenominationsDetail.vue'
import Coupons from '@/views/user/Coupons.vue'
import RequestDetail from '@/views/user/RequestDetail.vue'
import Main from '@/components/user/Main.vue'
import DetailResumen from '@/views/user/DetailResumen.vue'
import Users from '@/views/user/Users.vue'
import Notaries from '@/views/user/Notaries.vue'
import NotaryDetail from '@/views/user/NotaryDetail.vue'
import NotaryAdd from '@/views/user/NotaryAdd.vue'

const routes: Array<RouteConfig> = [
  {
    path: '/dashboard',
    component: Index,
    children: [
      {
        path: 'inicio',
        name: 'Home',
        component: Home,
        beforeEnter: multiguard([User.validateData])
      },
      {
        path: 'solicitudes-terminadas',
        component: Main,
        children: [
          {
            path: '',
            name: 'Resumen',
            component: Resumen,
            beforeEnter: multiguard([User.validateData])
          },
          {
            path: ':company_id/detalle',
            name: 'DetailResumen',
            component: DetailResumen,
            beforeEnter: multiguard([User.validateData])
          }
        ]
      },
      {
        path: 'solicitudes-pendientes',
        component: Main,
        children: [
          {
            path: '',
            name: 'Requests',
            component: Requests,
            beforeEnter: multiguard([User.validateData])
          },
          {
            path: ':company_id/detalle',
            name: 'RequestDetail',
            component: RequestDetail,
            beforeEnter: multiguard([User.validateData])
          }
        ]
      },
      {
        path: 'denominaciones',
        component: Main,
        children: [
          {
            path: '',
            name: 'Denominations',
            component: Denominations,
            beforeEnter: multiguard([User.validateData])
          },
          {
            path: ':company_id/detalle',
            name: 'DenominationDetail',
            component: DenominationsDetail,
            beforeEnter: multiguard([User.validateData])
          }
        ]
      },
      {
        path: 'notarias',
        component: Main,
        children: [
          {
            path: '',
            name: 'Notaries',
            component: Notaries,
            beforeEnter: multiguard([User.validateData])
          },
          {
            path: ':notary_id/detalle',
            name: 'NotaryDetail',
            component: NotaryDetail,
            beforeEnter: multiguard([User.validateData])
          },
          {
            path: 'agregar',
            name: 'NotaryAdd',
            component: NotaryAdd,
            beforeEnter: multiguard([User.validateData])
          }
        ]
      },
      {
        path: 'cupones',
        name: 'Coupons',
        component: Coupons,
        beforeEnter: multiguard([User.validateData])
      },
      {
        path: 'usuarios',
        name: 'Users',
        component: Users,
        beforeEnter: multiguard([User.validateData])
      }
    ]
  }
]

export default routes
