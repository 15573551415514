










































































































































































































































































































































































































































































































/* eslint no-use-before-define: 0 */
// Utils
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
// Components
import PartnerStatute from '@/components/user/statuteRender/PartnerStatute.vue'
import DelegateStatute from '@/components/user/statuteRender/DelegateStatute.vue'
import StatuteDataPending from '@/components/user/statuteRender/StatuteDataPending.vue'
import ModalRenderProtocolized from '@/components/user/statuteRender/ModalRenderProtocolized.vue'
import RepresentativeStatute from '@/components/user/statuteRender/RepresentativeStatute.vue'
// Types
import CompanyTypes from '@/store/types/CompanyTypes'
// Services
import RenderDocs from '@/services/RenderDocs'

@Component({
  components: { RepresentativeStatute, PartnerStatute, DelegateStatute, StatuteDataPending, ModalRenderProtocolized }
})
export default class CompanyStatute extends Vue {
  renderModal = { showModal: false }
  collapseCompany = { show: false }
  collapseSocialAddress = { show: false }
  collapseCouncil = { show: false }
  collapseProtocol = { show: false }
  collapseMandateContract = { show: false }
  collapseActivity = { show: false }
  collapseClause = { show: false }
  collapseDelegate = { show: false }
  clause = 'Por definir'
  urlObject = ''
  previewPDF = false
  file: any = null
  sign = false
  statute: any = {
    socios: {
      '90b463a4-f02f-4576-bcf2-7991ada6f2b1': {
        id: '90b463a4-f02f-4576-bcf2-7991ada6f2b1',
        socio_nombre: 'DANIEL GERARDO RODRIGUEZ REYNA',
        socio_nacionalidad: 'mexicana',
        socio_estado_nacimiento: 'MONTERREY',
        socio_fecha_nacimiento: 'TRES DE NOVIEMBRE DEL A\u00d1O MIL NOVECIENTOS NOVENTA',
        socio_estado_civil: 'CASADO',
        socio_ocupacion: '',
        socio_rfc: 'RORD901103V48',
        socio_curp: 'RORD901103HNLDYN05',
        socio_tipo_identificacion: 'credencial para votar',
        socio_tipo_identificacion_numero: '184038728',
        socio_correo: 'daniel@onestartup.mx',
        socio_acciones: 'NUEVE MIL SETECIENTOS',
        socio_direccion: 'PATABAM 3907 COLONIA DELGAS',
        is_manager: false,
        has_powers: false
      },
      '830e129b-a0f8-46fe-9cba-5baeb966b464': {
        id: '830e129b-a0f8-46fe-9cba-5baeb966b464',
        socio_nombre: 'JOSE FRANCISCO SALAZAR DELGADO',
        socio_nacionalidad: 'mexicano',
        socio_estado_nacimiento: 'Jerez Zacatecas',
        socio_fecha_nacimiento: 'VEINTID\u00f3S DE JULIO DEL A\u00d1O MIL NOVECIENTOS NOVENTA Y DOS',
        socio_estado_civil: 'soltero',
        socio_ocupacion: '',
        socio_rfc: 'SADF920722433',
        socio_curp: 'SADF920722HZSLLR03',
        socio_tipo_identificacion: 'credencial para votar',
        socio_tipo_identificacion_numero: '212627868',
        socio_correo: 'francisco@onestartup.mx',
        socio_acciones: 'TRESCIENTOS',
        socio_direccion: 'PATABAM 3907 COLONIA DELGAS',
        is_manager: false,
        has_powers: false
      }
    },
    legal_representatives_user: {
      '0A': {
        id: 146,
        name: 'APODERADO 1',
        rfc: 'APODERADO_1',
        is_primary: true
      },
      '1A': {
        id: 146,
        name: 'APODERADO 2',
        rfc: 'APODERADO_2',
        is_primary: false
      }
    },
    // Notary variables
    fecha_firma_proto: '',
    numero_de_hojas_firmadas_por_delegado: '15 (quince)',
    // Delegate variables
    nombre_delegado_especial: 'JACOB ZAZUETA FRAUSTO',
    delegado_especial_rfc: 'ZAFJ890626DI0',
    delegado_especial_nacionalidad: 'Mexicana',
    delegado_especial_lugar_fecha_nacimiento: 'Culiacan, Sinaloa el 26 de junio de 1989',
    delegado_especial_estado_civil: 'Soltero',
    delegado_especial_ocupacion: 'Estudiante',
    delegado_especial_domicilio: 'Calle Canizalez 1005, Colonia Centro, C.P. 82000, Mazatlán , Sinaloa, México',
    delegado_especial_curp: 'ZAFJ890626HSLZRC05',
    // Company variables
    numero_de_hojas_autorizacion: '15 (quince)',
    autorizacion_denominacion: 'Mi empresa',
    folio_denominacion: 'A202210131138409885',
    fecha_denominacion: 'DOCE DE octubre DEL A\u00d1O DOS MIL VEINTID\u00f3S',
    company_activity: 'Otro',
    company_description: 'Nuevo objeto social shalalalalal',
    company_products: 'Env\u00edos locales, env\u00edos nacionales, plataforma para compra de gu\u00edas de paqueter\u00eda, servicio de paqueter\u00eda, servicio de transportaci\u00f3n, servicios de log\u00edstica, plataforma de log\u00edstica y gesti\u00f3n de flotillas',
    domicilio_social: 'Mazatlán, Sinaloa',
    extranjeros: true,

    capital_social: 'DIEZ MIL',
    representatives: '',
    administrador_unico: '',
    presidente: 'DANIEL GERARDO RODRIGUEZ REYNA',
    secretario: 'JOSE FRANCISCO SALAZAR DELGADO',
    comisario: 'OSWALDO OCHOA HERRERA',
    comisario_rfc: 'EXTF900101NI1',
    representante_nombre: '',
    representante_rfc: '',
    fecha_firma_cliente: '',
    apendice_uno: 'B',
    apendice_dos: 'C',
    apendice_tres: 'D',
    apendice_cuatro: 'E'
  }

  async created () {
    if (this.validateStageComplete) return
    await this.getData()
    this.loading(false)
    this.$root.$on('bv::collapse::state', (collapseId: string, isJustShown: boolean) => {
      if (collapseId === 'companytoggle') this.collapseCompany.show = isJustShown
      if (collapseId === 'socialaddresstoggle') this.collapseSocialAddress.show = isJustShown
      if (collapseId === 'counciltoggle') this.collapseCouncil.show = isJustShown
      if (collapseId === 'protocoltoggle') this.collapseProtocol.show = isJustShown
      if (collapseId === 'mandatecontracttoggle') this.collapseMandateContract.show = isJustShown
      if (collapseId === 'activitytoggle') this.collapseActivity.show = isJustShown
      if (collapseId === 'clausetoggle') this.collapseClause.show = isJustShown
      if (collapseId === 'delegatetoggle') this.collapseDelegate.show = isJustShown
    })
  }

  get validateStageComplete () {
    return this.company.stages.findIndex((stage: any) => stage.stage_name === 'documents_approved' && stage.is_completed) === -1
  }

  noSpacesStart (value: string) {
    return value.trimStart()
  }

  async getData () {
    this.loading(true)
    const [error, response] = await RenderDocs.getData(this.company.id)
    if (error) {
      this.loading(false)
      return this.$toasted.global.error(error)
    }
    this.statute = response.data.data
    /* if (this.statute.statuto_articles_second) {
      Object.values(this.statute.statuto_articles_second).forEach((val: any) => {
        if (this.clause === '' || this.clause === 'Por definir') this.clause = val.replace(/\r\n/g, '')
        else this.clause += '\n' + val.replace(/\r\n/g, '')
      })
    } else this.clause = '' */
    Object.values(this.statute.socios).forEach((partner: any) => {
      this.statute[partner.id] = partner
    })
    if (this.statute.legal_representatives_user) {
      Object.values(this.statute.legal_representatives_user).forEach((representative: any) => {
        this.statute[representative.id] = representative
      })
    }
    await this.updateAndGetRender()
  }

  async updateAndGetRender () {
    this.loading(true)
    const [error, response] = await RenderDocs.render(this.company.id, this.statute)
    if (error) {
      this.loading(false)
      return this.$toasted.global.error(error)
    }
    const reader = new FileReader()
    reader.readAsDataURL(new Blob([response.data]))
    reader.onload = () => {
      if (reader.result) {
        if (typeof reader.result === 'string') this.file = reader.result.replace('application/octet-stream', 'application/pdf')
      }
    }
  }

  async save () {
    const article = this.clause.split(/[\r\n]+/)
    const statutoArticle: any = {}
    article.map((text: string, index: number) => {
      statutoArticle[(index + 1).toString()] = text
    })
    this.statute.statuto_articles_second = statutoArticle
    await this.updateAndGetRender()
    this.loading(false)
  }

  async approveRender () {
    this.loading(true)
    // eslint-disable-next-line
    const [error, response] = await RenderDocs.approveRender(this.company.id)
    if (error) {
      this.loading(false)
      return this.$toasted.global.error(error)
    }
    this.loading(false)
    this.$toasted.global.success({ message: 'Render aprobado' })
  }

  async sendToSign () {
    this.loading(true)
    // eslint-disable-next-line
    const [errorRender, responseRender] = await RenderDocs.approveRender(this.company.id)
    if (errorRender) {
      this.loading(false)
      return this.$toasted.global.error(errorRender)
    }
    // eslint-disable-next-line
    const [error, response] = await RenderDocs.sendToSign(this.company.id)
    if (error) {
      this.loading(false)
      return this.$toasted.global.error(error)
    }
    this.sign = false
    this.loading(false)
    this.$toasted.global.success({ message: 'Enviado a firma' })
  }

  searchErrors () {
    let check = false
    Object.values(this.statute.socios).forEach((partner: any) => {
      if (this.statute[partner.id].socio_nombre === '' || !this.statute[partner.id].socio_nombre ||
        this.statute[partner.id].socio_nacionalidad === '' || !this.statute[partner.id].socio_nacionalidad ||
        this.statute[partner.id].socio_estado_nacimiento === '' || !this.statute[partner.id].socio_estado_nacimiento ||
        this.statute[partner.id].socio_fecha_nacimiento === '' || !this.statute[partner.id].socio_fecha_nacimiento ||
        this.statute[partner.id].socio_estado_civil === '' || !this.statute[partner.id].socio_estado_civil ||
        this.statute[partner.id].socio_ocupacion === '' || !this.statute[partner.id].socio_ocupacion ||
        this.statute[partner.id].socio_direccion === '' || !this.statute[partner.id].socio_direccion ||
        this.statute[partner.id].socio_rfc === '' || !this.statute[partner.id].socio_rfc ||
        this.statute[partner.id].socio_curp === '' || !this.statute[partner.id].socio_curp ||
        this.statute[partner.id].socio_tipo_identificacion === '' || !this.statute[partner.id].socio_tipo_identificacion ||
        this.statute[partner.id].socio_tipo_identificacion_numero === '' || !this.statute[partner.id].socio_tipo_identificacion_numero ||
        this.statute[partner.id].socio_correo === '' || !this.statute[partner.id].socio_correo ||
        this.statute[partner.id].socio_acciones === '' || !this.statute[partner.id].socio_acciones) check = true
    })
    return check
  }

  searchErrorsDenomination () {
    let check = false
    if (this.statute.autorizacion_denominacion === '' || !this.statute.autorizacion_denominacion ||
        this.statute.folio_denominacion === '' || !this.statute.folio_denominacion ||
        this.statute.fecha_denominacion === '' || !this.statute.fecha_denominacion ||
        this.statute.numero_de_hojas_autorizacion === '' || !this.statute.numero_de_hojas_autorizacion) check = true
    return check
  }

  searchErrorsCompanyActivity () {
    let check = false
    if (this.statute.company_activity === '' || !this.statute.company_activity) check = true
    return check
  }

  searchErrorsCouncil () {
    if (this.company.has_unique_administrator) {
      return (this.statute.capital_social === '' || !this.statute.capital_social ||
        this.statute.administrador_unico === '' || !this.statute.administrador_unico ||
        this.statute.comisario === '' || !this.statute.comisario ||
        this.statute.comisario_rfc === '' || !this.statute.comisario_rfc)
    } else {
      return (this.statute.capital_social === '' || !this.statute.capital_social ||
        this.statute.presidente === '' || !this.statute.presidente ||
        this.statute.secretario === '' || !this.statute.secretario ||
        this.statute.comisario === '' || this.statute.comisario_rfc === '')
    }
  }

  searchErrorsDelegate () {
    return (this.statute.nombre_delegado_especial === '' || !this.statute.nombre_delegado_especial ||
      this.statute.delegado_especial_rfc === '' || !this.statute.delegado_especial_rfc ||
      this.statute.delegado_especial_nacionalidad === '' || !this.statute.delegado_especial_nacionalidad ||
      this.statute.delegado_especial_lugar_fecha_nacimiento === '' || !this.statute.delegado_especial_lugar_fecha_nacimiento ||
      this.statute.delegado_especial_estado_civil === '' || !this.statute.delegado_especial_estado_civil ||
      this.statute.delegado_especial_ocupacion === '' || !this.statute.delegado_especial_ocupacion ||
      this.statute.delegado_especial_domicilio === '' || !this.statute.delegado_especial_domicilio ||
      this.statute.delegado_especial_curp === '' || !this.statute.delegado_especial_curp)
  }

  searchErrorsProtocol () {
    return (this.statute.fecha_firma_proto === '' || !this.statute.fecha_firma_proto ||
      this.statute.numero_de_hojas_firmadas_por_delegado === '' || !this.statute.numero_de_hojas_firmadas_por_delegado ||
      this.statute.apendice_uno === '' || !this.statute.apendice_uno ||
      this.statute.apendice_dos === '' || !this.statute.apendice_dos ||
      this.statute.apendice_tres === '' || !this.statute.apendice_tres ||
      this.statute.apendice_cuatro === '' || !this.statute.apendice_cuatro)
  }

  @Getter(`CompanyModule/${CompanyTypes.getters.GET_DATA}`) company: any
  @Action('UPDATE_STATE_LOADING') loading: any
}
