import to from 'await-to-js'
import axios from '@/utils/axios-api'
import { Store } from '@/interfaces/Notary'

const URL_PHP = process.env.VUE_APP_BASE_URL_PHP

export default class Notary {
  static async index () {
    return await to(axios.get(`${URL_PHP}/v1/admin/notaries-index`)) as any
  }

  static async getNotariesPaginated (page: number, search: string) {
    return await to(axios.get(`${URL_PHP}/v1/admin/notaries?page=${page}${search}`)) as any
  }

  static async show (notaryId: string) {
    return await to(axios.get(`${URL_PHP}/v1/admin/notaries/${notaryId}`)) as any
  }

  static async getAnalitycs (notaryId: string, month: string, year: string) {
    return await to(axios.get(`${URL_PHP}/v1/notary/analytics/${notaryId}`, { params: { year, month } })) as any
  }

  static async getUsersNotary (notaryId: string) {
    return await to(axios.get(`${URL_PHP}/v1/users/notary/${notaryId}`)) as any
  }

  static async store (data: Store) {
    return await to(axios.post(`${URL_PHP}/v1/admin/notaries`, data)) as any
  }

  static async updateNotary (notaryId: number, companyId: string) {
    return await to(axios.put(`${URL_PHP}/v1/admin/notary/${notaryId}/company/${companyId}`)) as any
  }
}
