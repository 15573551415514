







// Utils
import { Component, Vue } from 'vue-property-decorator'
// Components
import CouponsRequests from '@/components/tables/CouponsRequests.vue'

@Component({
  components: { CouponsRequests }
})
export default class Coupons extends Vue {
}
