



































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
// Types
import CompanyTypes from '@/store/types/CompanyTypes'
// Services
import Company from '@/services/Company'

@Component({
})
export default class ModalEditCommissary extends Vue {
  editShares: any = { showModal: false }
  commissary = ''

  mounted () {
    this.editShares = this.$attrs.value
    this.commissary = this.company.commissary_council.name
  }

  noSpacesStart (value: string) {
    return value.trimStart()
  }

  async saveCommissary () {
    this.loading(true)
    const [error] = await Company.setCommissaryName(this.company.id, this.commissary)
    if (error) {
      this.loading(false)
      return this.$toasted.global.error(error)
    }
    await this.updateCompany(this.company.id)
    this.loading(false)
    this.editShares.showModal = false
    this.$toasted.global.success({ message: 'Se edito el comisario de la empresa' })
  }

  @Getter(`CompanyModule/${CompanyTypes.getters.GET_DATA}`) company: any
  @Action(`CompanyModule/${CompanyTypes.actions.UPDATE_DATA}`) updateCompany: any
  @Action('UPDATE_STATE_LOADING') loading: any
}
