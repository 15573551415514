





























import { Component, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import UserService from '@/services/User'
import { validationMixin } from 'vuelidate'
import validations from '@/validations/recovery-password'

@Component({
  mixins: [validationMixin],
  validations: validations
})
export default class Recovery extends Vue {
  mounted () {
    if (!this.$route.query.m || this.$route.query.m === '') {
      this.$toasted.global.error()
      return this.$router.push({ name: 'PasswordRecovery' })
    }
  }

  async resent () {
    this.loading(true)
    const [error] = await UserService.resetPasswordEmail({ identity: this.$route.query.m.toString() })
    this.loading(false)
    if (error) return this.$toasted.global.error(error)
    return this.$toasted.global.success({ message: 'Se reenvío el email' })
  }

  @Action('UPDATE_STATE_LOADING') loading: any
}
