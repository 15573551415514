


































































// Styles
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/es'
// Utils
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { validationMixin } from 'vuelidate'
import moment from 'moment'
// Validations
import validations from '@/validations/approved-or-reject-denomination'
// Interfaces
import UploadImage from '@/interfaces/UploadImage'
// Components
import DatePicker from 'vue2-datepicker'
import InputFile from '@/components/InputFile.vue'
// Types
import CompanyTypes from '@/store/types/CompanyTypes'
// Services
import Company from '@/services/Company'
import Documents from '@/services/Documents'

@Component({
  components: { InputFile, DatePicker },
  mixins: [validationMixin],
  validations: validations
})
export default class ModalApprovedDenomination extends Vue {
  file: any = ''
  moment = moment
  modalApproved: any = {
    type: '',
    legalNameSelected: 0,
    uniqDenomination: '',
    uniqDenominationKey: '',
    totalPages: '',
    authorizationDate: new Date(),
    showModal: false,
    update: false
  }

  mounted () {
    this.modalApproved = this.$attrs.value
  }

  noSpacesStart (value: string) {
    return value.trimStart()
  }

  onlyNumbers (value: string) {
    value = value.replace(/[^0-9]/g, '')
    return value.trimStart()
  }

  async approvedLegalName () {
    this.loading(true)
    // Approved legal name
    const datetime = moment(this.modalApproved.authorizationDate, 'DD-MM-YYYY').unix()
    const [error] = await Company.approvedOrRejectLegalName(
      this.modalApproved.legalNameSelected,
      this.modalApproved.uniqDenomination,
      datetime,
      true,
      this.modalApproved.uniqDenominationKey,
      this.modalApproved.totalPages
    )
    if (error) {
      this.loading(false)
      return this.$toasted.global.error(error)
    }
    // Upload authorization
    const data: UploadImage = {
      identity: this.company.id,
      entity_type: 'company',
      document_type: 'legal_name_authorization',
      image: this.file,
      is_approved: 'yes'
    }
    const [errorUpload] = await Documents.uploadDocument(data)
    if (errorUpload) {
      this.loading(false)
      return this.$toasted.global.error(errorUpload)
    }
    this.modalApproved.showModal = false
    this.$toasted.global.success({ message: 'Se actualizo la información con éxito' })
    // Refresh company data
    const [errorUpdateData] = await this.setCompanyData(this.company.id)
    this.loading(false)
    if (errorUpdateData) return this.$toasted.global.error(errorUpdateData)
    // Segment event
    if (process.env.NODE_ENV === 'production') {
      window.analytics.identify(this.company.user.id, { name: this.company.user.full_name, email: this.company.user.email })
      window.analytics.track('AC_NameAuthorizedByNotary_OK', {
        user_id: this.company.user.id,
        company_name: this.company.name,
        company_id: this.company.id,
        full_name: this.company.user.full_name,
        email: this.company.user.email,
        phone: this.company.user.phone,
        legal_name: this.company.legal_name
      })
      window.analytics.reset()
    }
    this.modalApproved.update = true
  }

  @Action('UPDATE_STATE_LOADING') loading: any
  @Getter(`CompanyModule/${CompanyTypes.getters.GET_DATA}`) company: any
  @Action(`CompanyModule/${CompanyTypes.actions.UPDATE_DATA}`) setCompanyData: any
}
