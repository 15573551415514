













































































































// Utils
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
// Types
import CompanyTypes from '@/store/types/CompanyTypes'

@Component({
  components: {}
})
export default class DelegateStatute extends Vue {
  validEmail = true
  collapsestatute = { index: -1, show: false }
  statute: any = {}

  created () {
    this.statute = this.$attrs.value
  }

  noSpacesStart (value: string) {
    return value.trimStart()
  }

  @Action('UPDATE_STATE_LOADING') loading: any
  @Getter(`CompanyModule/${CompanyTypes.getters.GET_DATA}`) company: any
}
