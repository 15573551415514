

























































































































































































// Utils
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
// Components
import ModalEditCouncil from '@/components/user/companyDetail/ModalEditCouncil.vue'
// Types
import CompanyTypes from '@/store/types/CompanyTypes'

@Component({
  components: { ModalEditCouncil }
})
export default class CompanyCouncil extends Vue {
  editCouncil = { showModal: false, updateCouncil: 0 }
  uniqueAdministrator = { name: '', power: false, id: '' }
  president = { name: '', power: false, id: '' }
  secretary = { name: '', power: false, id: '' }
  members: any = []

  @Watch('editCouncil.updateCouncil')
  updateCouncil () { this.setData() }

  mounted () {
    this.setData()
  }

  setData () {
    this.company.council.map((member: any) => {
      if (member.council_role !== 'commissary' && member.partner) {
        let fullName = member.partner.name != null ? member.partner.name : ''
        fullName = member.partner.first_names != null ? fullName + ' ' + member.partner.first_names : fullName
        if (this.company.company_type !== 'sapi' && member.council_role === 'unique_administrator') {
          this.uniqueAdministrator.id = member.id
          this.uniqueAdministrator.power = member.sat_signature_faculty
          this.uniqueAdministrator.name = fullName
        }
        if (member.council_role === 'president') {
          this.president.id = member.id
          this.president.power = member.sat_signature_faculty
          this.president.name = fullName
        }
        if (member.council_role === 'secretary') {
          this.secretary.id = member.id
          this.secretary.power = member.sat_signature_faculty
          this.secretary.name = fullName
        }
        if (member.council_role === 'member') {
          this.members.push({ id: member.partner.id, power: member.sat_signature_faculty, name: fullName })
        }
      }
    })
  }

  @Getter(`CompanyModule/${CompanyTypes.getters.GET_DATA}`) company: any
}
