import to from 'await-to-js'
import axios from '@/utils/axios-api'

const BASE_URL_RENDER_DOC = 'https://render-docs-tally.herokuapp.com'
// const BASE_URL_RENDER_DOC = 'http://localhost:3000'
const URL_PHP = process.env.VUE_APP_BASE_URL_PHP

export default class RenderDocs {
  static async previewContitutive (data: any) {
    return await to(axios.post(`${BASE_URL_RENDER_DOC}/constitutive/render/document`, data)) as any
  }

  static async rfc (data: any) {
    return await to(axios.post(`${BASE_URL_RENDER_DOC}/rfc/render/document`, data)) as any
  }

  static async getData (company: string) {
    return await to(axios.get(`${URL_PHP}/v1/admin/data-render-edit/company/${company}`)) as any
  }

  static async render (company: string, data: any) {
    return await to(axios.post(`${URL_PHP}/v1/admin/render-post/${company}`, data, { responseType: 'arraybuffer' })) as any
  }

  static async renderProtocolized (company: string) {
    return await to(axios.get(`${URL_PHP}/v1/admin/render-protocolized/${company}`, { responseType: 'arraybuffer' })) as any
  }

  static async approveRender (company: string) {
    return await to(axios.post(`${URL_PHP}/v1/admin/save-render/${company}`)) as any
  }

  static async sendToSign (company: string) {
    return await to(axios.get(`${URL_PHP}/v1/admin/send-render-signature/company/${company}`)) as any
  }
}
