import store from '@/store'
import UserTypes from '@/store/types/UserTypes'

export default class User {
  static async validateData (to: any, from: any, next: any) {
    let user: any, error: any
    user = store.getters[`UserModule/${UserTypes.getters.GET_DATA}`]
    if (!user.id) {
      await store.dispatch('UPDATE_STATE_LOADING', true);
      [error, user] = await store.dispatch(`UserModule/${UserTypes.actions.UPDATE_DATA}`)
      await store.dispatch('UPDATE_STATE_LOADING', false)
      if (error) return next({ name: 'Login' })
    }
    if (user.role !== 'admin') return next({ name: 'Login' })
    return next()
  }
}
