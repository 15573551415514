























































































































































































// Utils
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'
import numeral from 'numeral'
import moment from 'moment'
import { debounce } from 'lodash'
// Services
import Notary from '@/services/Notary'
import Company from '@/services/Company'

@Component({
  filters: {
    currency (value: number) { return numeral(value).format('0,0.00') }
  }
})
export default class CompaniesRequest extends Vue {
  companySelected = ''
  amount = ''
  showModal = false
  search = ''
  currentSearch = ''
  notarySelected = 0
  notaries: any = []
  page = 1
  currentPage = 1
  totalPages = 1
  moment = moment
  companies: any = []
  loader = true

  @Watch('page')
  updateCompanies () {
    this.loader = true
    this.getCompanies()
  }

  searchCompany = debounce(async (search: string) => {
    await this.getCompaniesSearch(search)
  }, 500)

  async mounted () { this.prepareData() }

  async prepareData () {
    this.loading(true)
    try {
      await this.getNotaries()
      await this.getCompanies()
      this.loading(false)
    } catch (error) {
      this.loading(false)
    }
  }

  async getCompaniesSearch (search: string) {
    this.search = search = search.trimStart()
    if (search === '' && this.currentSearch === '') {
      this.loader = false
      return
    }
    this.loader = true
    this.page = 1
    const [error, response] = await Company.getCompaniesPaginated(this.page, '&search=' + search)
    if (error) {
      this.loader = false
      this.page = this.currentPage
      return this.$toasted.global.error(error)
    }
    this.currentPage = 1
    this.totalPages = response.data.data.last_page
    this.currentSearch = search
    this.formattedDataTable(response.data.data.data)
    this.loader = false
  }

  async getCompanies () {
    const [error, response] = await Company.getCompaniesPaginated(this.page, '&search=' + this.search)
    if (error) {
      this.loader = false
      return this.$toasted.global.error(error)
    }
    this.totalPages = response.data.data.last_page
    await this.formattedDataTable(response.data.data.data)
    this.loader = false
  }

  async getNotaries () {
    const [error, response] = await Notary.index()
    if (error) return this.$toasted.global.error(error)
    this.notaries = response.data.data
  }

  formattedDataTable (companies: any) {
    this.companies = companies.map((company: any) => {
      if (company.user !== null) {
        return {
          legal_name: company.legal_name,
          created_on: company.company_created_on_timestamp,
          responsable: company.user.full_name,
          contact: { email: company.user.email, phone: company.user.phone },
          payment: (company.user.orders.length) ? this.getAmountFormatted(company.user.orders) : null,
          // documents_approved: this.validateStageComplete(company, 'documents_approved'),
          // company_articles_mock: this.validateStageComplete(company, 'Notary_validation'),
          // firm_complete: this.validateStageComplete(company, 'signing_complete'),
          // signature_meeting: this.validateStageComplete(company, 'signature_meeting'),
          expedient_completed: this.validateFlags(company), // Sustituye documents_approved
          weetrust_id: this.validateFlags(company), // Sustituye company_articles_mock
          date_signature: this.validateFlags(company), // Sustituye signing_complete
          documents: company.testimony,
          company_id: company.id,
          notary: company.notary_id
        }
      }
    }).filter((company: any) => company !== undefined)
  }

  getAmountFormatted (orders: any) {
    const order = orders.filter((order: any) => order.status === 'complete')
    if (!order.length) return null
    return order[0].order_total.toString().length > 5 ? (order[0].order_total / 100) : order[0].order_total
  }

  async changeNotary (notaryId: number, companyId: string) {
    this.loading(true)
    const [error] = await Notary.updateNotary(notaryId, companyId)
    this.loading(false)
    if (error) return this.$toasted.global.error(error)
    this.$toasted.global.success({ message: 'Se actualizo la notaría con éxito' })
  }

  // validateStageComplete (company: any, stageName: string) { return company.stages.findIndex((stage: any) => stage.stage_name === stageName && stage.is_completed) !== -1 }
  validateFlags (company: any) {
    return company.flags.date_signature !== null && company.expedient_completed === true && company.flags.weetrust_id !== null
  }

  async createPayment () {
    this.loading(true)
    const [error] = await Company.createPaymentCompanyManual(this.companySelected, Number(this.amount))
    if (error) {
      this.loading(false)
      return this.$toasted.global.error(error)
    }
    this.showModal = false
    this.$toasted.global.success({ message: 'Se creo el pago con éxito' })
    this.prepareData()
  }

  @Action('UPDATE_STATE_LOADING') loading: any
  @State('UserModule') state: any
}
