import { RouteConfig } from 'vue-router'
import multiguard from 'vue-router-multiguard'
import Index from '@/views/auth/Index.vue'
import Login from '@/views/auth/Login.vue'
import PasswordRecovery from '@/views/auth/PasswordRecovery.vue'
import EmailRecoverySent from '@/views/auth/EmailRecoverySent.vue'
import InputNewPassword from '@/views/auth/InputNewPassword.vue'
import ErrorEmailRecover from '@/views/auth/ErrorEmailRecover.vue'
import SuccessEmailRecover from '@/views/auth/SuccessEmailRecover.vue'
// import Auth from '@/middlewares/Auth'

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: Index,
    children: [
      {
        path: '',
        name: 'Login',
        component: Login,
        beforeEnter: multiguard([])
      },
      {
        path: '/recuperar-contrasena',
        name: 'PasswordRecovery',
        component: PasswordRecovery
      },
      {
        path: '/email-recuperar-contrasena',
        name: 'EmailRecoverySent',
        component: EmailRecoverySent
      },
      {
        path: '/ingresa-nueva-contrasena',
        name: 'InputNewPassword',
        component: InputNewPassword
      },
      {
        path: '/error-restaurar-contrasena',
        name: 'ErrorEmailRecover',
        component: ErrorEmailRecover
      },
      {
        path: '/exito-restaurar-contrasena',
        name: 'SuccessEmailRecover',
        component: SuccessEmailRecover
      }
    ]
  }
]

export default routes
