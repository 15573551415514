import Vue from 'vue'
// PLUGINS
import './plugins/bootstrap-vue'
import './plugins/vue-validate'
import './plugins/vue-toasted'
import './plugins/font-awesone'
// UTILS
import App from './App.vue'
import router from './router'
import store from './store'
import Events from '@/mixins/Events'

Vue.config.productionTip = true

new Vue({
  router,
  store,
  mixins: [Events],
  render: h => h(App)
}).$mount('#app')
