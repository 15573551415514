import { required, email } from 'vuelidate/lib/validators'

const validations = {

  email: {
    required,
    email
  },
  password: {
    required
  }
}

export default validations
