





























































































































// Utils
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action, State } from 'vuex-class'
import numeral from 'numeral'
import moment from 'moment'
import { debounce } from 'lodash'
// Services
import User from '@/services/User'

@Component({
  filters: {
    currency (value: number) { return numeral(value).format('0,0.00') }
  }
})
export default class CompaniesRequest extends Vue {
  search = ''
  currentSearch = ''
  page = 1
  currentPage = 1
  totalPages = 1
  moment = moment
  users: any = []
  loader = true
  showDelete = false
  userSelected = { id: 0, full_name: '', email: '', first_names: '', last_names: '', phone: '' }

  @Watch('page')
  updateUsers () {
    this.loader = true
    this.getUsers()
  }

  searchCompany = debounce(async (search: string) => {
    await this.getUsersSearch(search)
  }, 500)

  async mounted () {
    this.prepareData()
  }

  async prepareData () {
    this.loading(true)
    try {
      await this.getUsers()
      this.loading(false)
    } catch (error) {
      this.loading(false)
    }
  }

  async getUsersSearch (search: string) {
    this.search = search = search.trimStart()
    if (search === '' && this.currentSearch === '') {
      this.loader = false
      return
    }
    this.loader = true
    this.page = 1
    const [error, response] = await User.getUsersPaginated(this.page, '&search=' + search)
    if (error) {
      this.loader = false
      this.page = this.currentPage
      return this.$toasted.global.error(error)
    }
    this.currentPage = 1
    this.totalPages = response.data.data.last_page
    this.currentSearch = search
    this.users = response.data.data.data
    this.loader = false
  }

  async getUsers () {
    const [error, response] = await User.getUsersPaginated(this.page, '&search=' + this.search)
    if (error) {
      this.loader = false
      this.loading(false)
      return this.$toasted.global.error(error)
    }
    this.currentPage = this.page
    this.totalPages = response.data.data.last_page
    this.users = response.data.data.data
    this.loader = false
    this.loading(false)
  }

  async actAsUser (email: string) {
    this.loading(true)
    const [error, response] = await User.getTokenFromUser(email)
    this.loading(false)
    if (error) return this.$toasted.global.error(error)
    window.open(
      `${process.env.VUE_APP_URL}/auth/yolo?access_token=${response.data.access_token}&refresh_token=${response.data.refresh_token}`,
      '_blank'
    )
  }

  async remove () {
    this.loading(true)
    const [error] = await User.deleteUser(this.userSelected.email)
    if (error) {
      this.loading(false)
      return this.$toasted.global.error(error)
    }
    this.search = ''
    this.currentPage = 1
    this.page = 1
    this.getUsers()
    this.$toasted.global.success({ message: 'Se elimino usuario con éxito' })
    this.showDelete = false
  }

  @Action('UPDATE_STATE_LOADING') loading: any
  @State('UserModule') state: any
}
