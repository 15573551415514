import Vue from 'vue'

export default Vue.mixin({
  methods: {
    isLetterPrevent (e: any) {
      const char = String.fromCharCode(e.keyCode)
      if (/^[0-9]+$/.test(char)) return true
      else e.preventDefault()
    },
    isLetterPreventAndLength (e: any, length = 10) {
      const value = e.target.value.length
      const char = String.fromCharCode(e.keyCode)
      if (length >= value && /^[0-9]+$/.test(char)) return true
      else e.preventDefault()
    },
    onlyNumberOfCharacters (e: any, size = 10) {
      console.error(e.target.value, size)
    }
  }
})
