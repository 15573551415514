import { required, email, maxLength } from 'vuelidate/lib/validators'

const validations = {
  name: { required },
  phone: { required, maxLength: maxLength(8) },
  email: { required, email },
  address: { required },
  postalCode: { required },
  state: { required },
  country: { required },
  contactNames: { required },
  contactLastNames: { required },
  googleMapsUrl: { required }
}

export default validations
