import { required, email } from 'vuelidate/lib/validators'

const validations = {
  email: {
    required,
    email
  }
}

export default validations
