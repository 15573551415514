































































































// Utils
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import moment from 'moment'
// Components
import ModalApprovedDenomination from '@/components/user/companyDetail/ModalApprovedDenomination.vue'
// Types
import CompanyTypes from '@/store/types/CompanyTypes'
// Services
import Company from '@/services/Company'

@Component({
  components: { ModalApprovedDenomination }
})
export default class Denominations extends Vue {
  showModal2 = false
  approved = false
  reject = false
  moment = moment
  legalNames: any = []
  modalApproved: any = {
    type: '',
    legalNameSelected: 0,
    uniqDenomination: '',
    uniqDenominationKey: '',
    total_pages: '',
    authorizationDate: new Date(),
    showModal: false,
    update: false
  }

  @Watch('modalApproved.authorizationDate', { immediate: true, deep: true })
  changeAuthorizationDate (currentValue: any) { this.modalApproved.authorizationDate = moment(currentValue, 'DD-MM-YYYY').format('DD-MM-YYYY') }

  @Watch('modalApproved.update')
  updateTable () {
    if (this.modalApproved.update) {
      this.modalApproved.update = false
      this.formattedDataTable()
    }
  }

  mounted () { this.formattedDataTable() }

  formattedDataTable () {
    this.legalNames = this.company.legal_names.map((legalName: any) => {
      if (legalName.is_approved) this.approved = true
      if (legalName.rejection_category) this.reject = true
      return {
        name: legalName.name,
        created_on: legalName.created_on,
        id: legalName.id,
        is_approved: legalName.is_approved,
        rejection_category: legalName.rejection_category
      }
    })
  }

  openModal (legalNameId: number) {
    this.modalApproved.type = 'approved'
    this.modalApproved.uniqDenomination = ''
    this.modalApproved.uniqDenominationKey = ''
    this.modalApproved.total_pages = ''
    this.modalApproved.authorizationDate = new Date()
    this.modalApproved.legalNameSelected = legalNameId
    this.modalApproved.showModal = true
  }

  async rejectLegalNames () {
    this.loading(true)
    const [error] = await Company.rejectLegalNames(this.company.id)
    if (error) {
      this.loading(false)
      return this.$toasted.global.error(error)
    }
    this.showModal2 = false
    this.$toasted.global.success({ message: 'Se actualizo la información con éxito' })
    const [errorUpdateData] = await this.setCompanyData(this.company.id)
    this.loading(false)
    if (errorUpdateData) return this.$toasted.global.error(errorUpdateData)
    this.approved = this.reject = false
    this.formattedDataTable()
  }

  @Action('UPDATE_STATE_LOADING') loading: any
  @Getter(`CompanyModule/${CompanyTypes.getters.GET_DATA}`) company: any
  @Action(`CompanyModule/${CompanyTypes.actions.UPDATE_DATA}`) setCompanyData: any
}
