



















// Utils
import { Component, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'
// Services
import Coupon from '@/services/Coupon'

@Component({
})
export default class ModalCouponDelete extends Vue {
  coupon: any = {
    showModal: false,
    update: false,
    name: ''
  }

  mounted () {
    this.coupon = this.$attrs.value
  }

  async remove () {
    this.loading(true)
    const [error] = await Coupon.delete(this.coupon.name)
    if (error) {
      this.loading(false)
      return this.$toasted.global.error(error)
    }
    this.$toasted.global.success({ message: 'Se elimino el cupón con éxito' })
    this.loading(false)
    this.coupon.showModal = false
    this.coupon.update = true
  }

  @Action('UPDATE_STATE_LOADING') loading: any
}
