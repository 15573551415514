import Vue from 'vue'
import Vuex from 'vuex'
import AuthModule from '@/store/modules/AuthModule'
import UserModule from '@/store/modules/UserModule'
import CompanyModule from '@/store/modules/CompanyModule'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false
  },
  mutations: {
    START_LOADING: (state) => { state.loading = true },
    FINISH_LOADING: (state) => { state.loading = false }
  },
  actions: {
    UPDATE_STATE_LOADING: ({ commit }, status) => {
      if (status) commit('START_LOADING')
      else commit('FINISH_LOADING')
    }
  },
  modules: {
    AuthModule,
    UserModule,
    CompanyModule
  }
})
