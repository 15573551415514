























































































/* eslint-disable camelcase */
// Utils
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
// Types
import CompanyTypes from '@/store/types/CompanyTypes'
// Services
import Company from '@/services/Company'

@Component({
})
export default class ModalEditPartner extends Vue {
  editPartner: any = { showModal: false, id: '' }
  validEmail = true
  checkEmail = true
  foreign = false
  partnerInfo = {
    name: '',
    first_names: '',
    email: '',
    civil_status: '',
    rfc: ''
  }

  @Watch('editPartner.id')
  updateCouncil () { if (this.editPartner.id) this.setData() }

  mounted () {
    this.editPartner = this.$attrs.value
    if (this.editPartner.id) this.setData()
  }

  setData () {
    const partner = this.company.partners.filter((partner: any) => partner.id === this.editPartner.id)
    this.foreign = partner[0].nationality !== 'México'
    this.partnerInfo.name = partner[0].name
    this.partnerInfo.first_names = partner[0].first_names
    this.partnerInfo.email = partner[0].email
    this.partnerInfo.civil_status = partner[0].civil_status
    this.partnerInfo.rfc = partner[0].rfc
    this.validate(this.partnerInfo.email)
  }

  noSpacesStart (value: string) {
    return value.trimStart()
  }

  formatRFC (value: string) {
    value = value.trimStart()
    return value.toUpperCase()
  }

  validate (email: string) {
    const re = /\S+@\S+\.\S+/
    this.validEmail = re.test(email)
    this.checkEmail = re.test(email)
  }

  async update () {
    this.loading(true)
    this.validate(this.partnerInfo.email)
    if (!this.validEmail) return this.$toasted.global.error({ message: 'El email no es valido' })
    const [error] = await Company.updatePartnerInfo(this.partnerInfo, this.editPartner.id, this.company.id)
    if (error) {
      this.loading(false)
      return this.$toasted.global.error(error)
    }
    await this.updateCompany(this.company.id)
    this.loading(false)
    this.editPartner.showModal = false
    this.$toasted.global.success({ message: 'Se edito la informacion de incorporación' })
  }

  @Getter(`CompanyModule/${CompanyTypes.getters.GET_DATA}`) company: any
  @Action(`CompanyModule/${CompanyTypes.actions.UPDATE_DATA}`) updateCompany: any
  @Action('UPDATE_STATE_LOADING') loading: any
}
