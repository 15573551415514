



































































/* eslint-disable camelcase */
// Utils
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
// Types
import CompanyTypes from '@/store/types/CompanyTypes'
// Services
import Company from '@/services/Company'

@Component({
})
export default class ModalEditIncorporation extends Vue {
  EditCompany: any = { showModal: false }
  company_type = ''
  company_name = ''
  company_activity = { activity: '', products: '', description: '' }
  descriptionMaxLength = 300
  activities: Array<string> = [
    'SaaS (Servicios de Software)',
    'Servicios de Consultoría (incluye tecnología)',
    'Fintech',
    'Comercio / E-commerce',
    'Construcción',
    'Fabricación / Producción',
    'Educación (incluye Tecnología)',
    'Otro'
  ]

  types: Array<string> = [
    'sapi',
    'sa'
  ]

  mounted () {
    this.EditCompany = this.$attrs.value
    this.resetData()
  }

  noSpacesStart (value: string) {
    return value.trimStart()
  }

  resetData () {
    this.company_type = this.company.company_type
    this.company_name = this.company.name
    this.company_activity = this.company.company_activity
      ? {
        activity: this.company.company_activity.activity,
        products: this.company.company_activity.products,
        description: this.company.company_activity.description
      }
      : { activity: '', products: '', description: '' }
  }

  async update () {
    this.loading(true)
    const [error] = await Company.updateCompany(
      this.company.id,
      this.company_type,
      this.company_activity
    )
    if (error) {
      this.loading(false)
      return this.$toasted.global.error(error)
    }
    await this.updateCompany(this.company.id)
    this.loading(false)
    this.EditCompany.showModal = false
    this.$toasted.global.success({ message: 'Se edito la informacion de incorporación' })
  }

  @Getter(`CompanyModule/${CompanyTypes.getters.GET_DATA}`) company: any
  @Action(`CompanyModule/${CompanyTypes.actions.UPDATE_DATA}`) updateCompany: any
  @Action('UPDATE_STATE_LOADING') loading: any
}
