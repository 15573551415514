












import { Component, Vue } from 'vue-property-decorator'
import LateralMenu from '@/components/user/LateralMenu.vue'
import Header from '@/components/user/Header.vue'
import Main from '@/components/user/Main.vue'

@Component({
  components: { LateralMenu, Header, Main }
})
export default class Index extends Vue {}
