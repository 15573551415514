





































































































// Utils
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
// Components
import ViewFilePartner from '@/components/user/companyDetail/ViewFilePartner.vue'
import ModalEditPartner from '@/components/user/companyDetail/ModalEditPartner.vue'
// Types
import CompanyTypes from '@/store/types/CompanyTypes'

@Component({
  components: { ViewFilePartner, ModalEditPartner }
})
export default class PartnerInfo extends Vue {
  partner: any = {}
  docPartner: any = { document_url: null }
  editPartner: any = { showModal: false, id: '' }
  url = ''

  @Watch('company')
  updatePartners () {
    const partner = this.company.partners.filter((partner: any) => partner.id === this.partner.id)
    if (partner.length) this.partner = partner[0]
  }

  mounted () {
    this.partner = this.$attrs.value
    this.editPartner.id = this.partner.id
    this.url = process.env.VUE_APP_URL
  }

  copyLink (partner: any) {
    navigator.clipboard.writeText(this.url + '/invited?partner=' + partner.id + '&company=' + this.company.id)
    this.$toasted.global.success({ message: 'Se ha copiado el link con éxito' })
  }

  @Action('UPDATE_STATE_LOADING') loading: any
  @Getter(`CompanyModule/${CompanyTypes.getters.GET_DATA}`) company: any
}
