





























import { Component, Vue } from 'vue-property-decorator'

@Component({
})
export default class SuccessEmailRecover extends Vue {
  mounted () {
    setTimeout(() => this.$router.push({ name: 'Home' }), 1500)
  }
}
