import { required, minLength, sameAs } from 'vuelidate/lib/validators'

const validations = {
  password: {
    required,
    containsOneNumber: (value: string) => /[0-9]/.test(value),
    minLength: minLength(8)
  },
  confirmPassword: {
    required,
    sameAs: sameAs('password')
  }
}

export default validations
