

















































// Utils
import { Component, Vue, Prop } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import EventBus from '@/event-bus'
// Services
import Documents from '@/services/Documents'

@Component({
})
export default class ViewFilePartner extends Vue {
  @Prop() fileName!: string
  @Prop() doc!: any
  showModal = false
  previewPDF = false
  file: any = null

  openModalDocuments (document: any) {
    EventBus.$emit('open-modal', document.label ? document : { label: this.fileName, ...document })
  }

  async previewOrDownload (document: any) {
    this.loading(true)
    this.showModal = false
    const [error, response] = await Documents.getDocument({
      entity_type: 'partner',
      identity: document.partner.id,
      document_type: document.type
    })
    this.loading(false)
    if (error) return this.$toasted.global.error(error)
    const reader = new FileReader()
    reader.readAsDataURL(new Blob([response.data]))
    reader.onload = () => {
      if (reader.result) {
        this.previewPDF = response.headers['content-type'].includes('application/pdf')
        if (typeof reader.result === 'string' && this.previewPDF) this.file = reader.result.replace('application/octet-stream', 'application/pdf')
        else this.file = reader.result
        this.showModal = true
      }
    }
  }

  @Action('UPDATE_STATE_LOADING') loading: any
}
