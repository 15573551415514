






















// Utils
import { Component, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import moment from 'moment'
// Components
import ViewFile from '@/components/user/companyDetail/ViewFile.vue'
// Types
import CompanyTypes from '@/store/types/CompanyTypes'

moment.locale('es')
@Component({
  components: { ViewFile }
})
export default class BillingInfo extends Vue {
  @Getter(`CompanyModule/${CompanyTypes.getters.GET_DATA}`) company: any
}
