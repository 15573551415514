















































































// Utils
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
// Components
import ModalEditDistribution from '@/components/user/companyDetail/ModalEditDistribution.vue'
// Types
import CompanyTypes from '@/store/types/CompanyTypes'

@Component({
  components: { ModalEditDistribution }
})
export default class ShareDistribution extends Vue {
  editShares = { showModal: false, updatePartners: 0 }
  totalShares = ''
  partners: any = []

  @Watch('editShares.updatePartners')
  updatePartners () { this.setData() }

  mounted () {
    this.setData()
  }

  totalSharesFormat (value: string) {
    return value.replace(/\d(?=(?:\d{3})+$)/g, '$&,')
  }

  setData () {
    this.totalShares = this.company.share_series.length ? this.totalSharesFormat(this.company.share_series[0].total_shares_in_series.toString()) : '-'
    this.partners = this.company.partners.map((partner: any) => {
      return {
        name: partner.name,
        first_names: partner.first_names,
        percentage: partner.shares[0].percentage === null ? '0' : partner.shares[0].percentage.toString(),
        id: partner.id
      }
    })
  }

  @Getter(`CompanyModule/${CompanyTypes.getters.GET_DATA}`) company: any
}
