







// Utils
import { Component, Vue } from 'vue-property-decorator'
// Services
import UsersRequests from '@/components/tables/UsersRequests.vue'

@Component({
  components: { UsersRequests }
})
export default class Users extends Vue {
}
