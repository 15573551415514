
































































































































































import { Component, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import Notary from '@/services/Notary'
import moment from 'moment'

@Component({
})
export default class Coupons extends Vue {
  moment= moment
  dateSelected = moment().format('M Y')
  total = 0
  search = ''
  backupData = []
  type = ''
  currentPage = 1
  perPage = 12
  notary = []
  analitycs = []
  tableData: any = {
    fields: [
      { key: 'full_name', label: 'Nombre', sortable: true },
      { key: 'email', label: 'Email', sortable: true },
      { key: 'last_sign_in_on', label: 'Último inicio de sesión', sortable: true },
      { key: 'role', label: 'Nivel de acceso', sortable: true }
    ],
    items: []
  }

  get totalRows () {
    return this.tableData.items.length
  }

  async mounted () {
    this.loading(true)
    await this.getDetail()
    await this.getUsers()
    await this.getAnalitycs()
    this.loading(false)
  }

  async getDetail () {
    const [err, res] = await Notary.show(this.$route.params.notary_id)
    if (err) {
      this.loading(false)
      return this.$toasted.global.error(err)
    }
    this.notary = res.data.data
  }

  async getUsers () {
    const [err, res] = await Notary.getUsersNotary(this.$route.params.notary_id)
    if (err) {
      this.loading(false)
      return this.$toasted.global.error(err)
    }
    this.tableData.items = res.data.data
    this.backupData = res.data.data
  }

  async getAnalitycs () {
    const [err, res] = await Notary.getAnalitycs(
      this.$route.params.notary_id,
      moment(this.dateSelected, 'M Y').format('M'),
      moment(this.dateSelected, 'M Y').format('Y')
    )
    if (err) {
      this.loading(false)
      return this.$toasted.global.error(err)
    }
    this.analitycs = res.data
  }

  searchUser () { this.tableData.items = this.backupData.filter((user: any) => user.full_name.toLowerCase().includes(this.search.toLowerCase())) }

  @Action('UPDATE_STATE_LOADING') loading: any
}
