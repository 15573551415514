






















































































import { Component, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import UserService from '@/services/User'
import AuthTypes from '@/store/types/AuthTypes'
import { validationMixin } from 'vuelidate'
import validations from '@/validations/input-new-password'

@Component({
  mixins: [validationMixin],
  validations: validations
})
export default class InputNewPassword extends Vue {
  password = ''
  confirmPassword = ''

  mounted () {
    if (!this.$route.query.email || this.$route.query.email === '' || !this.$route.query.token || this.$route.query.token === '') {
      this.$toasted.global.error()
      return this.$router.push({ name: 'PasswordRecovery' })
    }
  }

  async recovery () {
    this.loading(true)
    const identity = this.$route.query.email.toString()
    const token = this.$route.query.token.toString()
    const [error] = await UserService.resetPasswordEntry({ identity, password: this.password, token })
    if (error) {
      this.loading(false)
      this.$toasted.global.error(error)
      return this.$router.push({ name: 'ErrorEmailRecover' })
    }
    const [errorLogin] = await this.login({ email: identity, password: this.password })
    if (errorLogin) {
      this.loading(false)
      this.$toasted.global.error(error)
      return this.$router.push({ name: 'PasswordRecovery' })
    }
    this.loading(false)
    return this.$router.push({ name: 'SuccessEmailRecover' })
  }

  @Action('UPDATE_STATE_LOADING') loading: any
  @Action(`AuthModule/${AuthTypes.actions.LOGIN}`) login: any;
}
